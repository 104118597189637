export default {
  complete: false,
  title: "Modified Plank",
  training: {
    title: "Duration",
    amount: "3 repetitions of 30 seconds",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/plank.gif",
  video: "Modified Planks.m4v",
  description:
    "Get into kneeling position and onto elbows. Keeping abs tight, position your back and hips so that both are in a straight line. Hold for 30 seconds and repeat 3 times.",
};
