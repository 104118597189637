export default {
  complete: false,
  title: "Incisional Massage",
  training: {
    title: "duration",
    amount: "3 minutes",
  },
  equipmentNeeded: ["floor"],
  media: "./assets/images/exercises/incisionMassage.gif",
  description:
    "Using two fingers make small circles over the length of the scar and to the skin around the scar. The goal is to breakdown and scar tissue that may have formed post-operation.",
};
