export default {
  complete: false,
  title: "Heel Walks",
  training: {
    title: "Repetitions",
    amount: "20 repetitions",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/heelwalks.gif",
  video: "Heelwalks.m4v",
  description:
    "Perform a bridge while on heels. While holding the bridge position, slowly walk heels as far as possible without hips rotating sideways. Hold at end position for 3 seconds and then walk heels back slowly.",
};
