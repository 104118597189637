export default {
  complete: false,
  title: "Step Up Retro",
  training: {
    title: "repetitions",
    amount: "30 times",
  },
  equipmentNeeded: ["12 inch step"],
  media: "./assets/images/exercises/StepUpRetro.gif",
  description:
    "Start in front of the step or box. Step up backwards onto step with one foot. Come back down with the opposite foot.",
};
