export default {
  complete: false,
  title: "Double Leg Sport Cord",
  training: {
    title: "repetitions",
    amount: "1 minute",
  },
  equipmentNeeded: ["Sport Cord (Squat w/ Ankle Weights)"],
  media: "./assets/images/exercises/doublelegsportcord.gif",
  description:
    "Place the sport cord under both feet, set shoulder width apart. Hold the sport cord in each hand and do a squat. Adjust placement of cord to change resistance.",
};
