export default {
  complete: false,
  title: "Body Weight Squat",
  training: {
    title: "repetitions",
    amount: "3 sets of 10",
  },
  equipmentNeeded: ["Floor"],
  media: "./assets/images/exercises/bodyweightsquat.gif",
  description:
    "With feet shoulder width apart and hands out in front, perform a squat and return to standing position. Contract quads and glutes at end-range. Add weight to increase difficulty.",
};
