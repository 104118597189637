export default {
  complete: false,
  title: "Quad Stretch",
  training: {
    title: "repetitions",
    amount: "3 times (hold for 30 seconds)",
  },
  equipmentNeeded: ["Anchor"],
  media: "./assets/images/exercises/QuadStretch.gif",
  description:
    "While in a standing position, bend your knee back behind and hold your ankle/foot. Next, gently pull your knee into a more bent position.",
};
