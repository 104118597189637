import Routines from "./routines";

export default [
  {
    complete: false,
    length: "50 min",
    locked: true,
    routines: Routines.week1day1,
    title: "Basic Range Of Motion",
  },

  {
    complete: false,
    length: "50 min",
    locked: true,
    routines: Routines.week1day1,
    title: "Basic Range Of Motion",
  },

  {
    complete: false,
    length: "50 min",
    locked: true,
    routines: Routines.week1day1,
    title: "Basic Range Of Motion",
  },

  {
    complete: false,
    length: "50 min",
    locked: true,
    routines: Routines.week1day1,
    title: "Basic Range Of Motion",
  },

  {
    complete: false,
    length: "50 min",
    locked: true,
    routines: Routines.week1day1,
    title: "Basic Range Of Motion",
  },

  {
    complete: false,
    length: "50 min",
    locked: true,
    routines: Routines.week1day1,
    title: "Basic Range Of Motion",
  },

  {
    complete: false,
    length: "50 min",
    locked: true,
    routines: Routines.week1day1,
    title: "Basic Range Of Motion",
  },
];
