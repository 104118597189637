export default {
  complete: false,
  title: "Active Knee Extension",
  training: {
    title: "repetitions",
    amount: "10 times (hold for 10 seconds)",
  },
  equipmentNeeded: ["Chair/Surface"],
  media: "./assets/images/exercises/aromkneeextension.gif",
  description:
    "Sitting forward on chair straighten affected leg in front with heel on ground. Activate quad, pull toe toward shin and push heel into ground.",
};
