export default {
  complete: false,

  title: "Pretzel/Pirformis Stretch",

  training: {
    title: "repetitions",
    amount: "3 times (hold for 20 seconds)",
  },

  equipmentNeeded: ["Floor"],

  media: "./assets/images/exercises/pretzelstretch.gif",

  description:
    "Lying on your back, bend one leg at the knee and cross the foot of the other leg across your thight. Pull with your hands under the hamstring of the bent knee to stretch your glutes. Repeat on other leg.",
};
