export default {
  complete: false,
  title: "IT Band Stretch",
  training: {
    title: "Repetitions",
    amount: "3 x 30 sec",
  },
  equipmentNeeded: [],
  altEquipmentNeeded: ["Sidelying"],
  media: "./assets/images/exercises/itbandstretch.gif",
  description:
    "Place a strap around your foot. While lying on your back and leg up in front of you and knee straight, bring your leg across midline for a gentle stretch felt along your outer thigh.",
};
