import week1 from "./week1";
import week2 from "./week2";
import week3 from "./week3";
import week5 from "./week3";
import week6 from "./week6";
import week7 from "./week7";
import week8 from "./week8";
import week9 from "./week9";
import week12 from "./week12";
import week13 from "./week13";

export default {
  week1: week1,
  week2: week2,
  week3: week3,
  week5: week5,
  week6: week6,
  week7: week7,
  week8: week8,
  week9: week9,
  week12: week12,
  week13: week13,
};
