export default {
  complete: false,
  title: "Pike III",
  training: {
    title: "Duration",
    amount: "5 repetitions",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/pikes3.gif",
  video: "Pike 3.m4v",
  description:
    "Sit upright with legs out and knees bent. Stretch arms up overhead, lean back slightly, and hold for 10 seconds.",
};
