import { useFetch } from "@vueuse/core";
import { useToaster } from "@/stores/useToaster";
import Stripe from "stripe";
import { computed, reactive, Ref, ref } from "vue";

// const toaster = useToaster();

interface CreateStripeCustomerPayload {
  user_id: string;
  email: string;
  name?: string;
}

export const API_HOST = ref("");

const getLatestPreviewUrl = async () => {
  try {
    const { isFetching, error, data } = await useFetch(
      "https://api.vercel.com/v6/deployments?projectId=prj_DPCZgwwOxVfZE8Z5fTvl2a7L9RSX&target=",
      {
        method: "GET",
        headers: {
          Authorization: "Bearer lZCz8FQnZ6HnPhn02QAg5sl5",
        },
      }
    ).json();

    console.log({ isFetching, error, data });
    const firstDeployment = (
      data as Ref<VercelDeploymentsResponse>
    ).value.deployments.find(
      dep => dep.meta?.githubCommitRef === "dev" && dep.state === "READY"
    );
    console.log(firstDeployment?.url);
    API_HOST.value = `http://localhost:8080/${firstDeployment?.url}/`;
    // return `http://localhost:8080/${firstDeployment?.url}/`
  } catch (e: any) {
    console.error(e);
  }
};

if (process.env.VUE_APP_VERCEL_ENV !== "production") {
  console.log("Getting Latest Preview Url");
  getLatestPreviewUrl();
}

export async function createStripeCustomer(
  payload: CreateStripeCustomerPayload
): Promise<definitions["customers"]> {
  return new Promise((resolve, reject) => {
    try {
      const { data, onFetchResponse, onFetchError } = useFetch(
        API_HOST.value + "api/createCustomer",
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).json();

      onFetchResponse(response => {
        console.log(data.value as definitions["customers"]);
        resolve(data.value as definitions["customers"]);
      });

      onFetchError(error => {
        console.error(error.value);
        // notyf.error(error.value)
        reject(error.value);
      });
    } catch (e: any) {
      console.error(e);
      // toaster.error(e)
      reject(e);
    }
  });
}

interface CheckoutIntentPayload {
  customer_id: Stripe.Customer["id"];
  items: {
    product_id: Stripe.Product["id"];
    quantity: number;
  }[];
}

interface CheckoutIntentResponse {
  clientSecret: string;
}

export async function initializeCheckout(
  payload: CheckoutIntentPayload
): Promise<CheckoutIntentResponse> {
  return new Promise((resolve, reject) => {
    try {
      const { data, onFetchResponse, onFetchError } = useFetch(
        API_HOST.value + "api/initializeCheckout",
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).json();

      onFetchResponse(response => {
        console.log(data.value as CheckoutIntentResponse);
        resolve(data.value as CheckoutIntentResponse);
      });

      onFetchError(error => {
        console.error(error.value);
        // notyf.error(error.value)
        reject(error.value);
      });
    } catch (e: any) {
      console.error(e);
      // toaster.error(e)
      reject(e);
    }
  });
}

interface CheckoutSessionPayload {
  customer_id: Stripe.Customer["id"];
  items: {
    price: Stripe.Price["id"];
    quantity: number;
  }[];
  success_url: string;
  cancel_url: string;
}

interface CheckoutSessionResponse {
  checkoutUrl: string;
}

export async function initializeHostedCheckout(
  payload: CheckoutSessionPayload
): Promise<CheckoutSessionResponse> {
  return new Promise((resolve, reject) => {
    try {
      const { data, onFetchResponse, onFetchError } = useFetch(
        API_HOST.value + "api/createCheckoutSession",
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).json();

      onFetchResponse(response => {
        console.log(data.value as CheckoutSessionResponse);
        resolve(data.value as CheckoutSessionResponse);
      });

      onFetchError(error => {
        console.error(error.value);
        // notyf.error(error.value)
        reject(error.value);
      });
    } catch (e: any) {
      console.error(e);
      // toaster.error(e)
      reject(e);
    }
  });
}

interface CloudinarySignatureParams {
  for_upload: boolean;
  public_id?: string;
  upload_preset?: string;
}

interface CloudinarySignatureBody {
  for_upload: boolean;
  public_id?: string;
  upload_preset?: string;
}

interface CloudinarySignatureResponse {
  signature: string;
  timestamp: number | string;
  api_key: string;
  username: string;
}

export async function apiCloudinaryAuthenticate({
  for_upload = false,
  public_id,
  upload_preset,
}: CloudinarySignatureParams) {
  return new Promise(
    (resolve: (value: CloudinarySignatureResponse) => void, reject) => {
      try {
        const body: CloudinarySignatureBody = {
          for_upload,
          public_id,
          upload_preset,
        };
        const { data, onFetchResponse, onFetchError } = useFetch(
          API_HOST.value + "api/cloudinaryAuthenticate",
          {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).json();

        onFetchResponse(response => {
          const payload = {
            ...data.value,
            api_key: process.env.VUE_APP_CLOUDINARY_API_KEY as string,
            // username: process.env.VUE_APP_CLOUDINARY_API_USERNAME,
          };
          console.log(payload);
          resolve(payload);
        });

        onFetchError(error => {
          console.error(error);
          // notyf.error(error)
          reject(error.value);
        });
      } catch (e: any) {
        console.error(e);
        // notyf.error(e)
        reject(e);
      }
    }
  );
}
