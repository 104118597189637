export default {
  complete: false,
  title: "Terminal Knee Extensions",
  training: {
    title: "repetitions",
    amount: "3 sets of 10",
  },
  equipmentNeeded: ["Theraband/Anchor"],
  media: "./assets/images/exercises/TerminalKneeExtension.gif",
  description:
    "Place band around involved knee. Stand with knee slightly bent. Tighten the quadriceps to straighten the knee. Do not pull the knee completely straight.",
};
