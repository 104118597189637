export default {
  complete: false,
  title: "Pike IV",
  training: {
    title: "Repetitions",
    amount: "10 repetitions",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/pikes4.gif",
  video: "Pike 4.m4v",
  description:
    "Sit upright with legs out. Bring legs up off of the floor, while also lean back slightly to form a v-shape. Hold this position for 5 seconds. Lower legs to starting position and then repeat.",
};
