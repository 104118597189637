export default {
  complete: false,
  title: "Side Planks II",
  training: {
    title: "Duration",
    amount: "60 seconds on both sides",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/sideplank2.gif",
  video: "Side Plank.m4v",
  description:
    "Get on your side with both legs striaght, one top of each other. Using your forearm for support, keep core tight and in line with rest of the body. Lift hips off of the floor and hold for 30 seconds. Repeat on each side.",
};
