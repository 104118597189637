export default {
  complete: false,
  title: "Swiss Ball Bridge",
  training: {
    title: "Repetitions",
    amount: "20 repetitions",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballbridge.gif",
  video: "Swiss Ball Bridge.m4v",
  description:
    "Lie on your back with both feet on the swiss ball. Contract abs and lift hips off surface. Hold for 5 seconds before returning to starting position.",
};
