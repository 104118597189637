export default {
  complete: false,
  title: "Wall Sits (90˚)",
  training: {
    title: "repetitions",
    amount: "5 times (hold for 1 minute)",
  },
  equipmentNeeded: ["wall"],
  media: "./assets/images/exercises/WallSit60.gif",
  description:
    "With back flat against a wall, squat and contract abdominals to flatten back to the wall. Put most of the weight in your mid-foot or heels. Hold and repeat.",
};
