export default {
  complete: false,
  title: "Single Leg Curls",
  training: {
    title: "Repetitions",
    amount: "10 repetitions on both sides",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballlegcurlsingleleg.gif",
  video: "Swiss Ball Single Leg Curl.m4v",
  description:
    "Lie on your back with both feet on the swiss ball. Contract abs and lift hips off of the floor. Pull ball towards your hips with one leg, while other leg is extended. Then push the ball back away. Repeat for other leg.",
};
