export default {
  complete: false,
  title: "Swiss Ball Roll Out",
  training: {
    title: "Repetitions",
    amount: "20 repetitions",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballrollouts.gif",
  video: "Swiss Ball Roll Outs - Phase 1.m4v",
  description:
    "Get on your knees and lean forward to place both forearms onto the swiss ball. Keeping abs tight and spine neutral, roll forward as far as you can without arching your back. Arms should be extended outwards as far as possible. Hold this position for a moment and then return back to the starting position.",
};
