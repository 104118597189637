import Routines from "./routines";

export default [
  // Each object is a day
  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week2day1,
    title: "Basic Core Strength II-A",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week2day2,
    title: "Basic Core Strength II-B",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week2day1,
    title: "Basic Core Strength II-A",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week2day2,
    title: "Basic Core Strength II-B",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week2day1,
    title: "Basic Core Strength II-A",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week2day2,
    title: "Basic Core Strength II-B",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week2day1,
    title: "Basic Core Strength II-A",
  },
];
