import Routines from "./routines";

export default [
  // Each object is a day
  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week13day1,
    title: "Expert Core Strength I",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week13day1,
    title: "Expert Core Strength I",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week13day1,
    title: "Expert Core Strength I",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week13day1,
    title: "Expert Core Strength I",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week13day1,
    title: "Expert Core Strength I",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week13day1,
    title: "Expert Core Strength I",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week13day1,
    title: "Expert Core Strength I",
  },
];
