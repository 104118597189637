export default {
  complete: false,
  title: "Standing 4 Way Hip Extensions",
  training: {
    title: "repetitions",
    amount: "30 times (each direction)",
  },
  equipmentNeeded: ["Theraband/Anchor"],
  media: "./assets/images/exercises/4wayhiptheraband.gif",
  description:
    "Attach an elastic band to your ankle and tie other end to a secure structure. Start in a standing position with your leg forward and to the inside of your body. Next, move your leg back and outward as shown. Also move backwards, forwards and across the body. Complete in all 4 directions.",
};
