export default {
  complete: false,

  title: "Active Psoas Stretch",

  training: {
    title: "repetitions",
    amount: "3 times (hold for 20 seconds)",
  },

  equipmentNeeded: ["Floor"],

  media: "./assets/images/exercises/psoasstretch.gif",

  description:
    "Step forward with one leg and drop into a lunge position. With your back knee down, lean forward to stretch your hip. Repeat on other leg.",
};
