import Routines from "./routines";

export default [
  {
    complete: false,
    length: "70 min",
    locked: true,
    routines: Routines.week13day1,
    title: "Strength & Flexibility Advanced",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week13day2,
    title: "Strength & Core Advanced",
  },

  {
    complete: false,
    length: "70 min",
    locked: true,
    routines: Routines.week13day1,
    title: "Strength & Flexibility Advanced",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week13day2,
    title: "Strength & Core Advanced",
  },

  {
    complete: false,
    length: "70 min",
    locked: true,
    routines: Routines.week13day1,
    title: "Strength & Flexibility Advanced",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week13day2,
    title: "Strength & Core Advanced",
  },

  {
    complete: false,
    length: "full day",
    locked: true,
    routines: Routines.restDay,
    title: "rest day",
  },
];
