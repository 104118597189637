export default {
  complete: false,
  title: "Heel Slides",
  training: {
    title: "repetitions",
    amount: "3 sets of 10 (hold for 5 seconds)",
  },
  equipmentNeeded: ["optional: strap (belt)"],
  media: "./assets/images/exercises/HeelSlides.gif",
  description:
    "Lying on your back with knees straight, slide the affected heel towards your buttock as you bend your knee. Hold a gentle stretch in this position and then return to original position. Use strap if needed.",
};
