export default {
  complete: false,
  title: "Single Leg Sport Cord",
  training: {
    title: "repetitions",
    amount: "1 minute",
  },
  equipmentNeeded: ["Sport Cord (Reverse Lunge w/ Ankle Weights)"],
  media: "./assets/images/exercises/singlelegsportcord.gif",
  description:
    "Place your foot on top of the sport cord with your front leg and allow for enough resistance. Hold cord in one hand and do a lunge motion. Adjust placement of cord to change resistance.",
};
