export default {
  complete: false,
  title: "Jump Squat",
  training: {
    title: "repetitions",
    amount: "2 sets of 20",
  },
  equipmentNeeded: ["Floor"],
  media: "./assets/images/exercises/jumpsquat.gif",
  description:
    "From a standing position, do a normal squat and then jump upwards from the bottom of the squat and land lightly on your feet.",
};
