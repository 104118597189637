export default {
  complete: false,
  title: "Leg press (60˚)",
  training: {
    title: "repetitions",
    amount: "3 sets of 10",
  },
  equipmentNeeded: ["leg press machine (theraband loop)"],
  media: "./assets/images/exercises/LegPress.gif",
  description:
    "Sit on machine with back on padded support. Place feet on platform. Push legs into the platform away to a 60˚ angle by extending knees and hips. Return and repeat. Do not lock knees.",
};
