export default {
  complete: false,
  title: "Single Leg Bridges",
  training: {
    title: "repetitions",
    amount: "2 sets of 10 (hold for 5 seconds)",
  },
  equipmentNeeded: ["Floor"],
  media: "./assets/images/exercises/singlelegbridge.gif",
  description:
    "Laying on your back, prop up the affected leg with your foot on the ground and leave the other leg straight. Raise up on your shoulders and hold the bridge position for 5 seconds before resting.",
};
