export default {
  complete: false,
  title: "Bridges",
  training: {
    title: "Repetitions",
    amount: "30 repetitions",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/bridges.gif",
  video: "Bridges.m4v",
  description:
    "Draw belly button inwards toward spine and lift hips off surface keeping both feet and head on surface. Hold position for 3 seconds.",
};
