export default {
  complete: false,
  title: "Prone Extension",
  training: {
    title: "repetitions",
    amount: "3 sets of 10",
  },
  equipmentNeeded: ["floor"],
  media: "./assets/images/exercises/ProneExtension.gif",
  description:
    "While lying face down with your knee straight, slowly raise up leg off the ground. Maintain a straight knee the entire time.",
};
