import { createStore } from "vuex";
import router from "@/router";
import Groups, { Group, Protocol } from "@/data/groups";
import System from "@/data/system";
import { handleLogout } from "@/state/authState";

type Enrollment = {
  active: boolean;
  completed: boolean;
  groupId: number;
  protocolId: number;
  phase: number;
  week: number;
  day: number;
  routine: number;
  exercise: number;
};
interface StoreState {
  userProfile: {
    uid?: string;
    email?: string;
    displayName?: string;
    photoURL?: string;
    emailVerified?: boolean;

    enrolled?: Enrollment[];
    personalInfo?: {
      age: string;
      email: string;
      feet: string;
      gender: string;
      inches: string;
      name: string;
      weight: string;
    };
  };
  activeEnrollment: Protocol | null;
  activeDay: any | null;
  system: any;
  groups: any[];
  currentProtocol: Protocol | null;
  currentGroup: Group | null;
  currentRoutine: Routine | null;
}

// make initial requests here to pre-populate store

const USER_DEFAULT: StoreState["userProfile"] = {
  uid: "",
  email: "",
  displayName: "",
  photoURL: "",
  emailVerified: false,
  enrolled: [],
  personalInfo: {
    age: "",
    email: "",
    feet: "",
    gender: "",
    inches: "",
    name: "",
    weight: "",
  },
};

const store = createStore({
  state: () => ({
    userProfile: USER_DEFAULT,
    activeEnrollment: null,
    activeDay: null,
    system: System,
    groups: Groups,
    currentProtocol: null,
    currentGroup: null,
    currentRoutine: null,
  }),
  mutations: {
    setUserProfile(state: StoreState, val) {
      state.userProfile = val;
    },
    setActiveEnrollment(state: StoreState, val) {
      state.activeEnrollment = val;
    },
    setActiveDay(state: StoreState, val) {
      state.activeDay = val;
    },
    setProtocol(state: StoreState, val) {
      state.currentProtocol = val;
    },
    setGroup(state: StoreState, val) {
      state.currentGroup = val;
    },
    setRoutine(state: StoreState, val) {
      state.currentRoutine = val;
    },
  },
  actions: {
    // Protocols & Groups
    async findProtocol({ dispatch }, { groupId, protocolId }) {
      const group = await dispatch("findGroup", groupId);
      const protocol = group?.protocols.find(
        (protocol: Protocol) => protocol.id === protocolId
      );

      return {
        ...protocol,
        groupId: group.id,
      };
    },
    findGroup({ state }, id) {
      return state.groups.find(group => group.id === id);
    },
    activateEnrollment({ dispatch, commit, state }, enrollment) {
      const enrolled = state.userProfile.enrolled || [];
      enrolled.push(enrollment);

      commit("setActiveEnrollment", enrollment);
      dispatch("updateProfile", {
        enrolled,
      });
    },

    // Authentication
    async loginGoogle({ dispatch }) {
      // await dispatch("loginAuth", new firebase.auth.GoogleAuthProvider());
    },
    async loginAuth({ dispatch }, provider) {
      // return auth
      //   .signInWithPopup(provider)
      //   .then(result => {
      //     dispatch("updateProfile", {
      //       uid: result.user?.uid,
      //       email: result.user?.email,
      //       displayName: result.user?.displayName,
      //       photoURL: result.user?.photoURL,
      //       emailVerified: result.user?.emailVerified,
      //     });
      //   })
      //   .catch(error => {
      //     window.alert(error);
      //   });
    },
    async login({ dispatch }, form: { email: string; password: string }) {
      // // sign user in
      // const { user } = await auth.signInWithEmailAndPassword(
      //   form.email,
      //   form.password,
      // );
      //
      // // fetch user profile and set in state
      // return dispatch("fetchUserProfile", user);
    },
    async signup({ dispatch }, form: { email: string; password: string }) {
      // sign user up
      // const { user } = await auth.createUserWithEmailAndPassword(
      //   form.email,
      //   form.password,
      // );
      //
      // // create user object in userCollections
      // await usersCollection.doc(user?.uid).set({
      //   ...USER_DEFAULT,
      //   uid: user?.uid,
      //   email: user?.email,
      //   displayName: user?.displayName,
      //   photoURL: user?.photoURL,
      // });
      //
      // // fetch user profile and set in state
      // return dispatch("fetchUserProfile", user);
    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      // const userProfile = await usersCollection.doc(user.uid).get();
      //
      // // set user profile in state
      // commit("setUserProfile", userProfile.data());
      // console.log("Fetched User: ", userProfile.data());
      //
      // // change route to dashboard
      // if (router.currentRoute.value.path === "/login") {
      //   router.push("/");
      // }
    },
    async updateProfile({ dispatch }, payload: StoreState["userProfile"]) {
      // const userId = auth.currentUser?.uid;
      //
      // if (payload.email && payload.email !== auth.currentUser?.email) {
      //   // await auth.currentUser?.verifyBeforeUpdateEmail(payload.email)
      //   await auth.currentUser?.updateEmail(payload.email);
      //   // await auth.currentUser?.sendEmailVerification();
      // }
      // // update user object
      // console.log("updateUserProfile");
      // const userRef = await usersCollection.doc(userId).update({
      //   ...USER_DEFAULT,
      //   ...payload,
      //   emailVerified: auth.currentUser?.emailVerified,
      // });
      //
      // dispatch("fetchUserProfile", { uid: userId });
      // // update all posts by user
      // const postDocs = await fb.postsCollection.where('userId', '==', userId).get()
      // postDocs.forEach(doc => {
      //   fb.postsCollection.doc(doc.id).update({
      //     userName: user.name
      //   })
      // })
      //
      // // update all comments by user
      // const commentDocs = await fb.commentsCollection.where('userId', '==', userId).get()
      // commentDocs.forEach(doc => {
      //   fb.commentsCollection.doc(doc.id).update({
      //     userName: user.name
      //   })
      // })
    },
    async logout({ commit }) {
      // log user out
      await handleLogout();

      // clear user data from state
      commit("setUserProfile", USER_DEFAULT);

      // redirect to login view
      router.push("/login");
    },
  },
});

export default store;
