export default {
  complete: false,
  title: "Swiss Ball Plank",
  training: {
    title: "Duration",
    amount: "3 repetitions of 15 seconds",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballplanks1.gif",
  video: "Swiss Ball Planks.m4v",
  description:
    "From your knees, put both forearms on to the swiss ball and raise your legs off the surface onto your toes. With a tight core and straight back, hold this position for 20 seconds.",
};
