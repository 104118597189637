export default {
  complete: false,
  title: "Single Leg Squat",
  training: {
    title: "Repetitions",
    amount: "15 repetitions on both sides",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/singlelegwallsquat.gif",
  video: "Single Leg Squat.m4v",
  description:
    "From a standing position, put one leg out in front of you. Then lower yourself into a squat position with one leg remaining extended. If you have difficulty, place heel of extended leg on the ground. Return to starting position. Repeat for both legs.",
};
