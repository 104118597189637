export default {
  complete: false,
  title: "Treadmill",
  training: {
    title: "duration",
    amount: "20 minutes",
  },
  equipmentNeeded: ["Treadmill (Stationary Bike)"],
  media: "./assets/images/exercises/treadmill.gif",
  description:
    "Once you are able to walk pain free, you can begin treadmill program. Begin by walking at 3.5 mph and increase speed as comfortable.",
};
