export default {
  complete: false,
  title: "Kneeling Flexion",
  training: {
    title: "Repetitions",
    amount: "30 repetitions",
  },
  equipmentNeeded: ["Dumbell/Medicine Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/kneelingoverheadextension.gif",
  video: "High Kneeling Overhead Flexion.m4v",
  description:
    "Get into a kneeling position with a 5 lb dumbell. Keeping abs tight and raise the dumbell overhead without arching back. Return to starting position and repeat movement. Stop if your back is being strained.",
};
