export default {
  complete: false,
  title: "V-Sits",
  training: {
    title: "repetitions",
    amount: "3 times (hold for 30 seconds)",
  },
  equipmentNeeded: ["floor"],
  media: "./assets/images/exercises/vsits.gif",
  description:
    "Lie flat on your back with your legs out straight in front of you. Lift both legs up toward the ceiling. Crunch the upper body up and reach both legs. Then lower the legs and your upper body back down toward the ground.",
};
