export default {
  complete: false,
  title: "Bird Dogs",
  training: {
    title: "Duration",
    amount: "90 seconds",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/quadrupeduilaterallegarms.gif",
  video: "Bird Dog.m4v",
  description:
    "Go on hands and knees without arching your back. Stabilize your core and align your torso from your head to your hips. Lift and straighten one arm out in front and the opposite leg behind you without arching your back or letting your torso twist. Hold for 3 seconds before returning to starting position. Alternate on both sides.",
};
