export default {
  complete: false,
  title: "Overhead Flexion",
  training: {
    title: "Repetitions",
    amount: "30 repetitions",
  },
  equipmentNeeded: ["Dumbell/Medicine Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/overheadinflection.gif",
  video: "Supine Overhead Flexion.m4v",
  description:
    "Starting with 5 to 10 lb weight and hold with both hand directly above chest. Bring weight slowly overhead until you cannot keep back flat and abs contracted. Hold position for 2 seconds then returnt to start.",
};
