import { ref } from "vue";

import useSupabase from "@/composable/useSupabase";

const supabase = useSupabase();

export const allUsers = ref<User[]>([]);
export const allRoles = ref<UserRole[]>([]);
export const UserModel = () => ({
  id: undefined,
  title: "",
});
/**
 * Retreive all Users from the Database
 */
export async function fetchUsers() {
  try {
    const { data: users, error } = await supabase
      .from("users")
      .select("*")
      .order("id");

    if (error) {
      console.log("error", error);
      return;
    }
    // handle for when no users are returned
    if (users === null) {
      allUsers.value = [];
      return;
    }
    // store response to allUsers
    allUsers.value = users;
    // console.log('got users!', allUsers.value)
  } catch (err) {
    console.error("Error retrieving data from db", err);
  }
}

/**
 * Retreive all Users from the Database
 */
export async function fetchUsersByRole(role = "Client") {
  try {
    const { data: users, error } = await supabase
      .from("users")
      .select("*, roles!users_role_id_fkey (*)")
      .eq("roles.title", role)
      .order("id");

    if (error) {
      console.log("error", error);
      return;
    }
    // handle for when no users are returned
    if (users === null) {
      allUsers.value = [];
      return;
    }
    // store response to allUsers
    allUsers.value = users;
    // console.log('got users!', allUsers.value)
  } catch (err) {
    console.error("Error retrieving data from db", err);
  }
}
/**
 * Retrieve all Users from the Database
 */
export async function fetchUser(id: string) {
  try {
    const { data: user, error } = await supabase
      .from("users")
      .select(
        "*, customer_id:customers (stripe_customer_id, environment), coach:coach_id (*)"
      )
      .eq("id", id)
      .eq(
        "customer_id.environment",
        process.env.VUE_APP_VERCEL_ENV === "production" ? "live" : "test"
      )
      .limit(1, { foreignTable: "customers" })
      .single();

    if (error) {
      console.log("error", error);
      return;
    }
    // handle for when no users are returned
    if (user === null) {
      return;
    }

    if (user.customer_id.length)
      user.customer_id = user.customer_id[0].stripe_customer_id;

    // store response to allUsers
    return user;
    // console.log('got users!', allUsers.value)
  } catch (err) {
    console.error("Error retrieving data from db", err);
  }
}

/**
 *  Add a new user to database
 */
export async function addUser(user: User): Promise<null | User> {
  try {
    const { data, error } = await supabase.from("users").insert(user).single();

    if (error) {
      alert(error.message);
      console.error("There was an error inserting", error);
      return null;
    }

    console.log("Created a new user");
    return data;
  } catch (err) {
    alert("Error");
    console.error("Unknown problem inserting to db", err);
    return null;
  }
}

export async function updateUser(user: User) {
  try {
    const { id, coach, customer_id, ...data } = user;
    const { error } = await supabase
      .from("users")
      .update(data)
      .eq("id", id)
      .single();

    if (error) {
      alert(error.message);
      console.error("There was an error updating", error);
      return;
    }

    console.log("Updated user", user);
  } catch (err) {
    alert("Error");
    console.error("Unknown problem updating record", err);
  }
}

/**
 *  Deletes a user via its id
 */
export async function deleteUser(user: User) {
  try {
    await supabase.from("users").delete().eq("id", user.id);
    console.log("deleted user", user.id);
  } catch (error) {
    console.error("error", error);
  }
}
