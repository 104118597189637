export default {
  complete: false,
  title: "Prone Curls",
  training: {
    title: "repetitions",
    amount: "3 sets of 10",
  },
  equipmentNeeded: ["floor"],
  media: "./assets/images/exercises/ProneCurl.gif",
  description:
    "While lying face down, slowly bend your knee as you bring your foot towards your back.",
};
