export default {
  complete: false,
  title: "Side Plank III",
  training: {
    title: "Repetitions",
    amount: "15 repetitions on each side",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/sideplanks3.gif",
  video: "Side Plank with Thoracic Rotation.m4v",
  description:
    "Lay on your side with both legs straight on top of each other. With your forearm for support, tighten your core, and keep your back straight. Lift hips off of the floor and top arm upwards. Rotate top arm underneath body and then return to starting position. Repeat on both sides.",
};
