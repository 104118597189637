export default {
  complete: false,
  title: "Leg Raises",
  training: {
    title: "Repetitions",
    amount: "30 repetitions for each leg",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/straightlegraise30.gif",
  video: "Straight Leg Raise.m4v",
  description:
    "Lie on back while maintaining a flat back and abs contacted. Lift one leg 60 degrees off surface and hold for 3 seconds. Repeat for opposite leg.",
};
