export default {
  complete: false,
  title: "Crunches",
  training: {
    title: "Repetitions",
    amount: "30 repetitions",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/basiccrunch.gif",
  video: "Basic Crunch.m4v",
  description:
    "With core tight and arms crossed over chest, lift upper body towards legs. Feet should remain on the ground. Keep your head looking up and do not curl neck forwards.",
};
