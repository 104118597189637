export default {
  complete: false,
  title: "Dead Bugs",
  training: {
    title: "Duration",
    amount: "90 seconds",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/deadbugs.gif",
  video: "Dead Bug - Phase 3.m4v",
  description:
    "With one arm up have the opposite leg bent at 90 degrees. Your other arm and leg should be fully straight and in line with body. Alternate this position between each side for 90 seconds.",
};
