export default {
  complete: false,
  title: "Calf Stretch",
  training: {
    title: "Repetitions",
    amount: "3 x 30sec",
  },
  equipmentNeeded: [],
  altEquipmentNeeded: ["Down Dog"],
  media: "./assets/images/exercises/calfstretch.gif",
  description:
    "Standing in front of a wall, step forward with one foot and keep the leg behind you straight. Lean forward towards the wall and support yourself with your arms, as you allow your front knee to bend until you feel a stretch in the back leg. Adjust distance from wall to control the degree of stretching.",
};
