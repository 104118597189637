export default {
  complete: false,
  title: "Advanced Leg Raise",
  training: {
    title: "Repetitions",
    amount: "30 repetitions",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/advancedstraightlegraises.gif",
  video: "Advanced Straight Leg Raise.m4v",
  description:
    "Lie on your back and tighten your core. Bend one leg up towards chest and keep hip and knee at 90 degree angles. Lift opposite leg 60 degrees off the ground and hold for 3 seconds.",
};
