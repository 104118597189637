export default {
  complete: false,
  title: "Bird Dog (legs only)",
  training: {
    title: "Duration",
    amount: "90 seconds",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/quadrupedunilaterallegextension.gif",
  video: "Bird Dog - Legs.m4v",
  description:
    "Begin on your hands and knees with a straight back and tightened core. Lift one leg up and extend it behind you, keeping the leg straight without arching your back or turning your torso. Hold for 3 seconds before returning to starting position. Alternate between each leg.",
};
