export default {
  complete: false,
  title: "Modified Side Plank",
  training: {
    title: "Repetitions",
    amount: "30 repetitions on both sides",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/sideplank1.gif",
  video: "Modified Side Planks.m4v",
  description:
    "Lay on side with knees slightly bent and forearm offering support. Keep back and hips aligned and your core tightened. Lift hips off of surface and hold for 3 seconds before returning to floor.",
};
