export default {
  complete: false,
  title: "Swiss Ball Leg Raises",
  training: {
    title: "Repetitions",
    amount: "20 repetitions",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballlegraises.gif",
  video: "Swiss Ball Leg Raises.m4v",
  description:
    "Lie on back with both feet on a swiss ball. Contract abs and lift hips off of the floor. Alternate raising each leg 6 to 10 inches off the ball. Hold at top for a moment and return to starting position.",
};
