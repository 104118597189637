import Routines from "./routines";

export default [
  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week5day1,
    title: "Basic Muscle Activation",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week5day1,
    title: "Basic Muscle Activation",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week5day1,
    title: "Basic Muscle Activation",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week5day1,
    title: "Basic Muscle Activation",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week5day1,
    title: "Basic Muscle Activation",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week5day1,
    title: "Basic Muscle Activation",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.checkUp,
    title: "Basic Muscle Activation",
  },
];
