export default {
  complete: false,
  title: "Oblique Crunch",
  training: {
    title: "Repetitions",
    amount: "45 x 2 sec hold",
  },
  equipmentNeeded: ["Dumbells"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/obliquecrunches.gif",
  description:
    "While lying on the floor with your knees bent, slowly raise up and to the side so that your shoulder blade clears off the floor. Keep your hands clasped behind your head the entire time. Alternate Sides",
};
