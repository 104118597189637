import EnrollInfo from "./enroll-info";
import Phases from "./phases";

export default {
  id: 0,
  category: "general pain",
  enrolled: false,
  enrollInfo: EnrollInfo,
  length: "14 weeks",
  media: "./assets/images/protocols/lower-back-protocol-splash.jpg",
  title: "lower back",
  phases: Phases,
};
