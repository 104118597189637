export default {
  complete: false,
  title: "Patellar Mobilization",
  training: {
    title: "repetitions",
    amount: "30 times each direction",
  },
  equipmentNeeded: ["optional: towel (pillow)"],
  media: "./assets/images/exercises/PatellarMobilization.gif",
  description:
    "While lying with your knee bent to about 30˚ (you can roll a pillow/towel under the knee), find the borders of your kneecap, gently push side to side and up and down.",
};
