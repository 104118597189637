export default {
  complete: false,
  title: "Marching I",
  training: {
    title: "Duration",
    amount: "90 seconds",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballmarch.gif",
  video: "Swiss Ball Marching.m4v",
  description:
    "Sitting on a swiss ball, keep upright posture with both feet on the ground. Lift one leg 6 inches off of the surface, hold for 2 seconds, and repeat on other side.",
};
