export default {
  complete: false,
  title: "Hip Flexor Stretch",
  training: {
    title: "Repetitions",
    amount: "3 x 30sec",
  },
  equipmentNeeded: [],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/hipflexorstretch.gif",
  description:
    "Stand in a staggered stance with one foot back and your knee straight. Place your other foot on a chair, stool, or bench. Place your hands on your hips and move your pelvis forward as one unit, without tipping down.",
};
