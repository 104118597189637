export default {
  complete: false,
  title: "Swiss Ball Curls",
  training: {
    title: "Repetitions",
    amount: "30 x 5 sec",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballcurl.gif",
  description:
    "Place feet on ball with back and shoulders flat on floor with arms out by side, or cross to increase difficulty. Lift your hips from floor and bend your knees to pull feet towards buttock. Then extend back to starting position but do not lower hips to ground.",
};
