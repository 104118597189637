export default {
  complete: false,
  title: "Flutter Kicks",
  training: {
    title: "repetitions",
    amount: "3 sets of 20",
  },
  equipmentNeeded: ["floor"],
  media: "./assets/images/exercises/FlutterKicks.gif",
  description:
    "While lying on your back, place your hands at your sides. Tilt your pelvis so that your lower back moves towards the floor and hold this position. Then raise your legs up in the air and move them up and down in an alternate fluttering motion as shown.",
};
