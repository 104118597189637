export default {
  complete: false,
  title: "Single Leg Sit Stand",
  training: {
    title: "Repetitions",
    amount: "20 repetitions",
  },
  equipmentNeeded: ["Chair"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballwallsit.gif",
  video: "Single Leg Sit-Stand.m4v",
  description:
    "Stand in front of a chair and raise one leg in front of you. Slowly squat until your bottom touches the chair and then raise up to the starting position. Repeat on both sides.",
};
