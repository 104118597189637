// Workout
import advancedSlr from "../../../../exercises/advanced-slr";
import basicCrunch from "../../../../exercises/basic-crunch";
import bodyWeightSquats from "../../../../exercises/body-weight-squats";
import bridges from "../../../../exercises/bridges";
import crunches from "../../../../exercises/crunches";
import deadBugs from "../../../../exercises/dead-bugs";
import heelWalks from "../../../../exercises/heel-walks";
import highKneelingDiagonals from "../../../../exercises/high-kneeling-diagonals";
import highKneelingOverheadFlexion from "../../../../exercises/high-kneeling-overhead-flexion";
import overheadFlexion from "../../../../exercises/overhead-flexion";
import pelvicTilts from "../../../../exercises/pelvic-tilts";
import planksPhaseI from "../../../../exercises/planks-phase-i";
import planksPhaseII from "../../../../exercises/planks-phase-ii";
import planksPhaseIII from "../../../../exercises/planks-phase-iii";
import planksPhaseIV from "../../../../exercises/planks-phase-iv";
import pikePhaseI from "../../../../exercises/pike-phase-i";
import pikesPhaseII from "../../../../exercises/pikes-phase-ii";
import pikesPhaseIII from "../../../../exercises/pikes-phase-iii";
import pikesPhaseIV from "../../../../exercises/pikes-phase-iv";
import quadrapedArmFlexion from "../../../../exercises/quadraped-arm-flexion";
import quadrapedUnilateralLegExtension from "../../../../exercises/quadraped-unilateral-leg-extension";
import quadrapedArmFlexionAndLegExt from "../../../../exercises/quadraped-arm-flexion-and-leg-ext";
import swissBallUnilateralArmLegFlexion from "../../../../exercises/quadraped-arm-flexion-and-leg-ext";
import sidePlanksPhaseI from "../../../../exercises/side-planks-phase-i";
import sidePlanksPhaseII from "../../../../exercises/side-planks-phase-ii";
import sidePlanksPhaseIII from "../../../../exercises/side-planks-phase-iii";
import sidePlanksPhaseIV from "../../../../exercises/side-planks-phase-iv";
import swissBallRollOuts from "../../../../exercises/swiss-ball-roll-outs";
import swissBallRollOutsPhaseII from "../../../../exercises/swiss-ball-roll-outs-phase-ii";
import sideLyingAbduction from "../../../../exercises/sidelying-abduction";
import singleLegBridgeWithKneeBent from "../../../../exercises/single-leg-bridge-with-knee-bent";
import straightLegRaise from "../../../../exercises/straight-leg-raise";
import swissBallBridge from "../../../../exercises/swiss-ball-bridge";
import singleLegBridgeWithStraightLeg from "../../../../exercises/single-leg-bridge-with-straight-leg";
import singleLegSitStand from "../../../../exercises/single-leg-sit-stand";
import swissBallWallSquats from "../../../../exercises/single-leg-wall-squats";
import swissBallLegCurls from "../../../../exercises/swiss-ball-leg-curls";
import swissBallMarching from "../../../../exercises/swiss-ball-marching";
import swissBallBalanceWithUnilateralLegLifts from "../../../../exercises/swiss-ball-balance-with-unilateral-leg-lifts";
import swissBallUnilateralLegCurl from "../../../../exercises/swiss-ball-unilateral-leg-curl";
import swissBallLegRaises from "../../../../exercises/swiss-ball-leg-raises";
import swissBallAlternatingArmLegMarching from "../../../../exercises/swiss-ball-alternating-arm-leg-marching";
import swissBallSupineBalancing from "../../../../exercises/swiss-ball-supine-balancing";
import swissBallPlanksPhaseI from "../../../../exercises/swiss-ball-planks-phase-i";
import swissBallPlanksPhaseII from "../../../../exercises/swiss-ball-planks-phase-ii";
import swissBallPlanksPhaseIII from "../../../../exercises/swiss-ball-planks-phase-iii";
import swissBallMarchingWithBilateralArmAndHipFlexion from "../../../../exercises/swiss-ball-marching-with-bilateral-arm-and-hip-flexion";
import swissBallUnilateralArmFlexion from "../../../../exercises/swiss-ball-unilateral-arm-flexion";
import swissBallCrunches from "../../../../exercises/swiss-ball-crunches";
import singleLegSquat from "../../../../exercises/single-leg-squat";
import singleLegWallSquats from "../../../../exercises/single-leg-wall-squats";
import singleLegWallSits from "../../../../exercises/single-leg-wall-sits";
import wallSits from "../../../../exercises/wall-sits";

export default {
  // Weekdays
  week1day1: [
    {
      description: "description",
      title: "workout",
      exercises: [
        pelvicTilts,
        bridges,
        straightLegRaise,
        basicCrunch,
        sideLyingAbduction,
        overheadFlexion,
        singleLegBridgeWithKneeBent,
      ],
    },
  ],

  week2day1: [
    {
      description: "description",
      title: "workout",
      exercises: [
        pelvicTilts,
        deadBugs,
        straightLegRaise,
        quadrapedArmFlexion,
        sideLyingAbduction,
        quadrapedUnilateralLegExtension,
        singleLegBridgeWithKneeBent,
      ],
    },
  ],

  week2day2: [
    {
      description: "description",
      title: "workout",
      exercises: [
        heelWalks,
        bridges,
        advancedSlr,
        basicCrunch,
        straightLegRaise,
        overheadFlexion,
        swissBallBridge,
      ],
    },
  ],

  week3day1: [
    {
      description: "description",
      title: "workout",
      exercises: [
        heelWalks,
        deadBugs,
        advancedSlr,
        quadrapedArmFlexion,
        singleLegBridgeWithStraightLeg,
        quadrapedUnilateralLegExtension,
        swissBallBridge,
      ],
    },
  ],

  week4day1: [
    {
      description: "description",
      title: "workout",
      exercises: [
        heelWalks,
        quadrapedArmFlexionAndLegExt,
        bodyWeightSquats,
        quadrapedArmFlexion,
        singleLegBridgeWithStraightLeg,
        sidePlanksPhaseI,
        swissBallBridge,
      ],
    },
  ],

  week4day2: [
    {
      description: "description",
      title: "workout",
      exercises: [
        heelWalks,
        deadBugs,
        swissBallLegCurls,
        quadrapedArmFlexion,
        swissBallMarching,
        sidePlanksPhaseI,
        quadrapedUnilateralLegExtension,
        highKneelingOverheadFlexion,
      ],
    },
  ],

  week5day1: [
    {
      description: "description",
      title: "workout",
      exercises: [
        planksPhaseI,
        quadrapedArmFlexionAndLegExt,
        swissBallLegCurls,
        bodyWeightSquats,
        swissBallMarching,
        sidePlanksPhaseI,
        highKneelingOverheadFlexion,
      ],
    },
  ],

  week6day1: [
    {
      description: "description",
      title: "workout",
      exercises: [
        planksPhaseI,
        highKneelingDiagonals,
        swissBallLegCurls,
        swissBallLegRaises,
        swissBallMarching,
        swissBallRollOuts,
        highKneelingOverheadFlexion,
      ],
    },
  ],

  week6day2: [
    {
      description: "description",
      title: "workout",
      exercises: [
        pikePhaseI,
        quadrapedArmFlexionAndLegExt,
        planksPhaseII,
        bodyWeightSquats,
        sidePlanksPhaseII,
        basicCrunch,
        swissBallAlternatingArmLegMarching,
      ],
    },
  ],

  week7day1: [
    {
      description: "description",
      title: "workout",
      exercises: [
        pikePhaseI,
        highKneelingDiagonals,
        planksPhaseII,
        swissBallLegRaises,
        sidePlanksPhaseII,
        swissBallRollOuts,
        swissBallAlternatingArmLegMarching,
      ],
    },
  ],

  week8day1: [
    {
      description: "description",
      title: "workout",
      exercises: [
        pikePhaseI,
        swissBallSupineBalancing,
        planksPhaseII,
        singleLegSitStand,
        sidePlanksPhaseII,
        swissBallPlanksPhaseI,
        swissBallAlternatingArmLegMarching,
      ],
    },
  ],

  week8day2: [
    {
      description: "description",
      title: "workout",
      exercises: [
        swissBallMarchingWithBilateralArmAndHipFlexion,
        highKneelingDiagonals,
        pikesPhaseII,
        swissBallLegRaises,
        swissBallUnilateralArmFlexion,
        swissBallRollOuts,
        swissBallCrunches,
      ],
    },
  ],

  week9day1: [
    {
      description: "description",
      title: "workout",
      exercises: [
        swissBallMarchingWithBilateralArmAndHipFlexion,
        swissBallSupineBalancing,
        pikesPhaseII,
        singleLegSitStand,
        swissBallUnilateralArmFlexion,
        swissBallPlanksPhaseI,
        swissBallCrunches,
      ],
    },
  ],

  week10day1: [
    {
      description: "description",
      title: "workout",
      exercises: [
        swissBallMarchingWithBilateralArmAndHipFlexion,
        planksPhaseIII,
        pikesPhaseII,
        swissBallBalanceWithUnilateralLegLifts,
        swissBallUnilateralArmFlexion,
        swissBallUnilateralLegCurl,
        swissBallCrunches,
      ],
    },
  ],

  week10day2: [
    {
      description: "description",
      title: "workout",
      exercises: [
        planksPhaseIII,
        swissBallSupineBalancing,
        swissBallPlanksPhaseII,
        singleLegSitStand,
        deadBugs,
        singleLegSquat,
        swissBallPlanksPhaseIII,
      ],
    },
  ],

  week11day1: [
    {
      description: "description",
      title: "workout",
      exercises: [
        pikesPhaseIII,
        planksPhaseIII,
        swissBallPlanksPhaseII,
        swissBallBalanceWithUnilateralLegLifts,
        singleLegSquat,
        swissBallUnilateralLegCurl,
        swissBallPlanksPhaseIII,
      ],
    },
  ],

  week12day1: [
    {
      description: "description",
      title: "workout",
      exercises: [
        pikesPhaseIII,
        swissBallUnilateralArmLegFlexion,
        swissBallPlanksPhaseII,
        sidePlanksPhaseIII,
        singleLegSquat,
        swissBallRollOutsPhaseII,
        swissBallPlanksPhaseIII,
      ],
    },
  ],

  week12day2: [
    {
      description: "description",
      title: "workout",
      exercises: [
        singleLegSquat,
        planksPhaseIII,
        pikesPhaseIV,
        swissBallBalanceWithUnilateralLegLifts,
        planksPhaseIV,
        swissBallUnilateralLegCurl,
        sidePlanksPhaseIV,
      ],
    },
  ],

  week13day1: [
    {
      description: "description",
      title: "workout",
      exercises: [
        singleLegSquat,
        swissBallUnilateralArmLegFlexion,
        pikesPhaseIV,
        deadBugs,
        planksPhaseIV,
        swissBallRollOutsPhaseII,
        sidePlanksPhaseIV,
      ],
    },
  ],
};
