export default {
  complete: false,
  title: "Quad Sets",
  training: {
    title: "repetitions",
    amount: "50 times (hold for 5 seconds)",
  },
  equipmentNeeded: ["floor"],
  media: "./assets/images/exercises/StaticQuadStretch.gif",
  description:
    "Tighten your top thigh muscle as you attempt to press the back of your knee downward towards the table. Hold for 5 seconds on each contraction.",
};
