export default {
  complete: false,
  title: "Passive Prone Flexion",
  training: {
    title: "repetitions",
    amount: "10 times (hold for 10 seconds)",
  },
  equipmentNeeded: ["Towel/Strap (Belt)"],
  media: "./assets/images/exercises/PROMProneFlexion.gif",
  description:
    "While lying flat on your stomach, use a stretching strap, or similar object, to help bend your knee. Hold for 10 seconds when you feel a stretch in your quadriceps, relax for 5 seconds and pull again.",
};
