import Days from "./days";

export default {
  phase1: [
    {
      complete: false,
      locked: true,
      title: "week 1",
      days: Days.week1,
    },

    {
      complete: false,
      locked: true,
      title: "week 2",
      days: Days.week2,
    },
  ],

  phase2: [
    {
      complete: false,
      locked: true,
      title: "week 3",
      days: Days.week3,
    },

    {
      complete: false,
      locked: true,
      title: "week 4",
      days: Days.week4,
    },
  ],

  phase3: [
    {
      complete: false,
      locked: true,
      title: "week 5",
      days: Days.week5,
    },

    {
      complete: false,
      locked: true,
      title: "week 6",
      days: Days.week6,
    },
  ],

  phase4: [
    {
      complete: false,
      locked: true,
      title: "week 7",
      days: Days.week7,
    },

    {
      complete: false,
      locked: true,
      title: "week 8",
      days: Days.week8,
    },
  ],

  phase5: [
    {
      complete: false,
      locked: true,
      title: "week 9",
      days: Days.week9,
    },

    {
      complete: false,
      locked: true,
      title: "week 10",
      days: Days.week10,
    },
  ],

  phase6: [
    {
      complete: false,
      locked: true,
      title: "week 11",
      days: Days.week11,
    },

    {
      complete: false,
      locked: true,
      title: "week 12",
      days: Days.week12,
    },
  ],

  phase7: [
    {
      complete: false,
      locked: true,
      title: "week 13",
      days: Days.week13,
    },
  ],
};
