export default {
  complete: false,
  title: "Swiss Ball Roll Out II",
  training: {
    title: "Repetitions",
    amount: "20 repetitions",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballrollout2.gif",
  video: "Swiss Ball Roll Outs - Phase 2.m4v",
  description:
    "Go onto your toes while placing forearms onto the swiss ball. Keep abs tight and a straight back. Roll ball out as far as you can without arching lower back. You should be on your elbows at the end range. Hold for 3 seconds and roll back slowly to starting position. Repeat.",
};
