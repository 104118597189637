export default {
  complete: false,
  title: "Marching II",
  training: {
    title: "Duration",
    amount: "60 seconds",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballlegmarches.gif",
  video: "Swiss Ball Alternating Arm-Leg Marching.m4v",
  description:
    "Sitting on a swiss ball, with feet flat and upright posture, lift one leg 6 inches off ground and bring opposite arms straight overhead. Hold for a moment and repeat on opposite side.",
};
