export default {
  complete: false,
  title: "Elliptical",
  training: {
    title: "duration",
    amount: "25 minutes",
  },
  equipmentNeeded: ["Elliptical (Stationary Bike)"],
  media: "./assets/images/exercises/Elliptical.gif",
  description:
    "Carefully get on the machine and begin the required motion to activate the device. Set the time and moderate resistance and begin your workout. Make sure the incline is below level 10.",
};
