import { ref } from "vue";
import {
  Session,
  Provider,
  UserCredentials,
} from "@supabase/gotrue-js/dist/main/lib/types";
import useSupabase from "@/composable/useSupabase";
import { createStripeCustomer } from "@/composable/useServerlessFunctions";

const userSession = ref<Session | null>(null);
const supabase = useSupabase();
/*
 * Handles user login via email + password into a supabase session.
 * If not password is empty, it will send a magic link to the users email address.
 */
async function handleLogin(credentials: UserCredentials) {
  try {
    const { error, user } = await supabase.auth.signIn({
      email: credentials.email,
      password: credentials.password,
    });
    if (error) {
      alert("Error logging in: " + error.message);
      return false;
    }
    // No error throw, but no user detected so send magic link
    if (!error && !user) {
      alert("Check your email for the login link!");
    }
    return true;
  } catch (err) {
    // console.error("Error thrown:", err.message);
    // alert(err.error_description || err);
    return false;
  }
}

/*
 * Handles signup provided a valid credentials object.
 */
async function handleSignup(credentials: UserCredentials) {
  try {
    const { email, password } = credentials;
    // prompt user if they have not filled populated their credentials
    if (!email || !password) {
      console.log("Please provide both your email and password.");
      return false;
    }
    const { user, error } = await supabase.auth.signUp({ email, password });
    if (error) {
      console.error(error.message);
      console.error(error, error.message);
      return false;
    }

    await createStripeCustomer({ user_id: user?.id as uuid, email });
    alert("Signup successful, confirmation mail should be sent soon!");
    return true;
  } catch (err) {
    console.error("Fatal error signing up");
    console.error("signup error", err);
    return false;
  }
}

/**
 * Handles signup via Third Pary Login.
 * https://supabase.io/docs/guides/auth#third-party-logins
 */
async function handleOAuthLogin(provider: Provider) {
  const { error } = await supabase.auth.signIn({ provider });
  if (error) console.error("Error: ", error.message);
}

/**
 * Handles password reset. Will send an email to the given email address.
 */
async function handlePasswordReset() {
  const email = prompt("Please enter your email:");
  if (!email) {
    window.alert("Email address is required.");
  } else {
    const { error } = await supabase.auth.api.resetPasswordForEmail(email);
    if (error) {
      alert("Error: " + error.message);
      return false;
    } else {
      alert("Password recovery email has been sent.");
      return true;
    }
  }
}

async function handleUpdateUser(credentials: UserCredentials) {
  try {
    const { error } = await supabase.auth.update(credentials);
    if (error) {
      alert("Error updating user info: " + error.message);
      return false;
    } else {
      // alert("Successfully updated user info!");
      return true;
    }
  } catch (err) {
    alert("Error updating user info: " + err);
    return false;
  }
}

/**
 * Handles logging a user out of a superbase session
 */
async function handleLogout() {
  console.log("logging out");
  try {
    const { error } = await supabase.auth.signOut();

    if (error) {
      alert("Error signing out");
      console.error("Error", error);
      return;
    }

    alert("You have signed out!");
  } catch (err) {
    alert("Unknown error signing out");
    console.error("Error", err);
  }
}

/**
 * Handles logging in a user from a magic link
 */
async function handleUrlSessionLogin() {
  try {
    const { data: session, error } = await supabase.auth.getSessionFromUrl({
      storeSession: true,
    });

    if (error) {
      console.error("Error", error);
      return false;
    }

    userSession.value = session;
    return session;
  } catch (err) {
    console.error("Error", err);
  }
}

export {
  userSession,
  handleLogin,
  handleUrlSessionLogin,
  handleOAuthLogin,
  handleSignup,
  handleLogout,
  handlePasswordReset,
  handleUpdateUser,
};
