export default {
  complete: false,
  title: "Single Leg Press",
  training: {
    title: "repetitions",
    amount: "3 sets of 10",
  },
  equipmentNeeded: ["Leg Press Machine (Theraband)"],
  media: "./assets/images/exercises/SingleLegPress.gif",
  description:
    "Place 1 foot on plate/bar and hold other leg in the air or to your chest. Slowly press leg (on bar/plate) straight and slowly return, repeat. Once set is complete perform on other leg.",
};
