export default {
  about:
    "We rely heavily on our backs to support us. As we age, our bone density, muscle elasticity, and strength will decrease, increasing the strain on our backs. This often manifests in aches and pains in the lower back region. The pain is often caused by spine compression, weak abdominal muscles, or back strains.",
  background: "./assets/images/protocols/lower-back-protocol-splash.jpg",
  candidate:
    "Lower back pain is one of the most common injuries experienced today. This progressive protocol helps strengthen your back and ease pain from minor ailments.",
  description:
    "If you are sufferring from minor lower back pain, then you may want to consider this protocol. It builds strength and mobility in the lower back to ease your typical aches and pains.",
  equipmentNeeded: ["Swiss Ball", "Dumbells", "Ankle Weights", "Yoga Mat"],
  media: "./assets/images/protocols/lower-back-enroll.png",
  summary:
    "It is recommended you visit a physician, this 13 week protocol can help you rebuild strength in the lower back and overcome pain and tightness. Enroll today and get started.",
};
