import Routines from "./routines";

export default [
  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week5day1,
    title: "Basic Muscle Activation",
  },

  {
    complete: false,
    length: "55 min",
    locked: true,
    routines: Routines.week7day2,
    title: "Basic Core Strengthening",
  },

  {
    complete: false,
    length: "55 min",
    locked: true,
    routines: Routines.week5day1,
    title: "Basic Core Strengthening",
  },

  {
    complete: false,
    length: "55 min",
    locked: true,
    routines: Routines.week7day2,
    title: "Basic Core Strengthening",
  },

  {
    complete: false,
    length: "55 min",
    locked: true,
    routines: Routines.week5day1,
    title: "Basic Core Strengthening",
  },

  {
    complete: false,
    length: "55 min",
    locked: true,
    routines: Routines.week5day1,
    title: "Endurance Training",
  },

  {
    complete: false,
    length: "full day",
    locked: true,
    routines: Routines.restDay,
    title: "Rest & Relaxation",
  },
];
