export default {
  complete: false,
  title: "Iron Cross Stretch",
  training: {
    title: "Repetitions",
    amount: "10 x 10 sec",
  },
  equipmentNeeded: [],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/ironcrossstretch.gif",
  description:
    "Lying on your back with your legs straight and arms out, lift one leg straight in the air. Then turn 90 degrees so that leg is hanging across the rest of your body and hold. Repeat on both sides to stretch your lower back.",
};
