export default {
  complete: false,
  title: "Extension Stretch",
  training: {
    title: "duration",
    amount: "5 minutes",
  },
  equipmentNeeded: ["foam roll (chair)"],
  media: "./assets/images/exercises/PassiveKneeExtension.gif",
  description:
    "While seated or lying down, straighten the involved leg allowing the heel to rest on a firm object. The back of the knee and calf should not be supported. Relax the thigh and leg muscles to allow the knee to straighten fully.",
};
