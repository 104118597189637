import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import { mailOutline, mailSharp, calendar, chatbubbles } from "ionicons/icons";
import useSupabase from "@/composable/useSupabase";
import { handleUrlSessionLogin, userSession } from "@/state/authState";
import { useToaster } from "@/stores/useToaster";
import { updateUser } from "@/composable/useUser";

type PhixRouteRecord = RouteRecordRaw & {
  meta?: {
    name?: string;
    menubar?: boolean;
    requiresAuth?: boolean;
    iosIcon?: string;
    mdIcon?: string;
  };
};

export const routes: PhixRouteRecord[] = [
  {
    path: "",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "",
        component: () => "<div />",
        beforeEnter: async (to, from, next) => {
          // return "/workouts";
          const hashParams = new URLSearchParams(
            window.location.hash.substr(1, window.location.hash.length)
          );
          console.log([...hashParams]);

          if (hashParams.has("type")) {
            console.log("login type:", hashParams.get("type"));
            switch (hashParams.get("type")) {
              case "email_change":
                await handleUrlSessionLogin().then(async session => {
                  if (session) {
                    await updateUser({
                      id: session.user!.id,
                      email: session.user!.email,
                    } as UserProfile);
                    next("/workouts");
                  }
                });
                break;
              case "magiclink":
                await handleUrlSessionLogin().then(() => {
                  next("/workouts");
                });
                break;
              case "invite":
              case "recovery":
                await handleUrlSessionLogin().then(() => {
                  next("/reset-password");
                });
                break;
              case "signup":
                next("/workouts");
                break;
              default:
                console.log("Un-managed login type: " + hashParams.get("type"));
                console.log([...hashParams]);
                break;
            }
          } else {
            next("/workouts");
          }
        },
      },
      {
        path: "/workouts",
        name: "workouts",
        component: () => import("../views/workouts/Workouts.vue"),
        meta: {
          name: "My Workouts",
          menubar: true,
          requiresAuth: true,
          iosIcon: calendar,
          mdIcon: calendar,
        },
      },
      {
        path: "/workouts/:workoutId/:enrollmentId",
        name: "workout",
        component: () => import("../views/workouts/Workout.vue"),
        props: true,
        meta: {
          name: "My Workout",
          menubar: true,
          requiresAuth: true,
          iosIcon: calendar,
          mdIcon: calendar,
        },
      },
      {
        path: "/workouts/:workoutId/task/:taskId",
        name: "workout-task",
        component: () => import("../views/workouts/WorkoutTask.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/programs/all",
        name: "programs",
        component: () => import("../views/protocols/Protocols.vue"),
        meta: {
          name: "Physical Therapy Protocols",
          menubar: true,
          requiresAuth: true,
          iosIcon: mailOutline,
          mdIcon: mailSharp,
        },
      },
      {
        path: "/programs/:categoryId/enroll/:id",
        name: "programs-enroll",
        component: () => import("../views/protocols/Enroll.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/protocols/:groupId/enroll/:id",
        name: "protocol-enroll-2",
        component: () => import("../views/protocols/Enroll.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      /*{
        path: "/protocols/enrolled",
        name: "protocols-enrolled",
        component: () => import("../views/protocols/Enrolled.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },*/
      {
        path: "/messages",
        name: "messages",
        component: () => import("../views/messages/Messages.vue"),
        meta: {
          name: "Messages",
          menubar: true,
          requiresAuth: true,
          iosIcon: chatbubbles,
          mdIcon: chatbubbles,
        },
      },
      {
        path: "/shop",
        component: () => import("../views/shop/ShopView.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/account",
        component: () => import("../views/Account.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/account-edit",
        component: () => import("../views/AccountEdit.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
    ],
    meta: {
      name: "Physical Therapy Protocols",
      menubar: true,
      requiresAuth: true,
      iosIcon: mailOutline,
      mdIcon: mailSharp,
    },
  },
  /*{
    path: "/schedules",
    component: () => import("../views/schedules/Schedule.vue"),
    children: [
      {
        path: "/schedules",
        redirect: "/schedules/full",
      },
      {
        path: "/schedules/full",
        name: "schedules-full",
        component: () => import("../views/schedules/Full.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/schedules/week",
        name: "schedules-week",
        component: () => import("../views/schedules/Week.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/schedules/day",
        name: "schedules-day",
        component: () => import("../views/schedules/Day.vue"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/programs/fitness",
    component: () => import("../views/programs/Fitness.vue"),
    meta: {
      name: "Fitness Programs",
      menubar: true,
      requiresAuth: true,
      iosIcon: mailOutline,
      mdIcon: mailSharp,
    },
  },
  {
    path: "/programs/prevention",
    component: () => import("../views/programs/Prevention.vue"),
    meta: {
      name: "Injury Prevention Programs",
      menubar: true,
      requiresAuth: true,
      iosIcon: mailOutline,
      mdIcon: mailSharp,
    },
  },*/
  {
    path: "/login",
    component: () => import("../views/auth/Login.vue"),
  },
  {
    path: "/register",
    component: () => import("../views/auth/Register.vue"),
  },
  {
    path: "/reset-password",
    component: () => import("../views/auth/SetPassword.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/folder/:id",
    component: () => import("../views/Folder.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(window.location.href);
  console.log(window.location.hash);
  if (window.location.hash.includes("message")) {
    const toaster = useToaster();
    toaster.openSimpleToast(
      window.location.hash.split("=")[1].replace(/\+/g, " ")
    );
  }
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const loggedIn = useSupabase().auth.user();

  // handle invite/recovery/reset urls
  if (window.location.hash.length > 0 && ["", "/"].includes(to.path)) next();

  if (requiresAuth && !loggedIn) {
    next("/login");
  } else if (["/login", "/register"].includes(to.path) && loggedIn) {
    next("/workouts");
  } else {
    next();
  }
});

export default router;
