// Warm up
import stationaryBikeBeginner from "../../../../exercises/stationary-bike-beginner";
import stationaryBikeNormal from "../../../../exercises/stationary-bike-normal";
import stationaryBikeAdvanced from "../../../../exercises/stationary-bike-advanced";
import extensionStretch from "../../../../exercises/knee-extension-stretch";
import activeKneeFlexion from "../../../../exercises/active-knee-flexion";
import activeKneeExtension from "../../../../exercises/active-knee-extension";
import passiveProneFlexion from "../../../../exercises/passive-prone-knee-flexion";
import passiveExtension from "../../../../exercises/passive-knee-extension";
import elliptical from "../../../../exercises/elliptical";
import treadMill from "../../../../exercises/treadmill";

// Workout
import weightShifting from "../../../../exercises/weight-shifting";
import passiveFlexion from "../../../../exercises/passive-knee-flexion";
import quadSets from "../../../../exercises/quad-sets";
import straightLegRaise from "../../../../exercises/straight-leg-raise";
import heelSlides from "../../../../exercises/heel-slides";
import patellarMobilization from "../../../../exercises/patellar-mobilization";
import sideLyingAbduction from "../../../../exercises/sidelying-abduction";
import proneExtension from "../../../../exercises/prone-knee-extension";
import calfRaises from "../../../../exercises/calf-raises";
import bridges from "../../../../exercises/bridges";
import legPress from "../../../../exercises/leg-press-60-degrees";
import miniSquats from "../../../../exercises/mini-squats";
import proneCurls from "../../../../exercises/prone-knee-curls";
import wallSits60 from "../../../../exercises/wall-sits-60-degrees";
import wallSits90 from "../../../../exercises/wall-sits-90-degrees";
import singleLegHeelRaise from "../../../../exercises/single-leg-heel-raise";
import singleLegBalance30sec from "../../../../exercises/single-leg-balance-30-sec";
import singleLegBalance1min from "../../../../exercises/single-leg-balance-1-min";
import doubleLegBalance from "../../../../exercises/double-leg-balance";
import standing4WayHipExtensions from "../../../../exercises/standing-4-way-hip-extensions";
import stepUpForward from "../../../../exercises/step-up-forward-6-in";
import stepUpLateral from "../../../../exercises/step-up-lateral-6-in";
import stepUpRetro from "../../../../exercises/step-up-retro-6-in";
import swissBallBridge from "../../../../exercises/swiss-ball-bridge";
import swissBallCurls from "../../../../exercises/swiss-ball-curls";
import terminalKneeExtensions from "../../../../exercises/terminal-knee-extensions";
import crunches from "../../../../exercises/crunches";
import planks from "../../../../exercises/planks";
import vSits from "../../../../exercises/v-sits";
import obliqueCrunches from "../../../../exercises/oblique-crunches";
import flutterKicks from "../../../../exercises/flutter-kicks";
import singleLegPress from "../../../../exercises/single-leg-press";
import bodyWeightSquat from "../../../../exercises/body-weight-squat";
import eccentricStepDowns from "../../../../exercises/eccentric-step-downs-12-in";
import hamstringCurls from "../../../../exercises/hamstring-curls";
import swissBallBridgesKneeBent from "../../../../exercises/swiss-ball-bridges-knee-bent";
import singleLegBridges from "../../../../exercises/single-leg-bridges";
import clamShells from "../../../../exercises/clam-shells";
import lungeWalks from "../../../../exercises/lunge-walks";
import singleLegSportCord from "../../../../exercises/single-leg-sport-cord";
import doubleLegSportCord from "../../../../exercises/double-leg-sport-cord";
import jumpSquat from "../../../../exercises/jump-squat";
import doubleLegLineJumpForwardBackward from "../../../../exercises/double-leg-line-jump-forward-backward";
import doubleLegLineJumpLateral from "../../../../exercises/double-leg-line-jump-lateral";
import doubleLegStepDropOff from "../../../../exercises/double-leg-step-drop-off";
import doubleLegLineJumpLateralSpeed from "../../../../exercises/double-leg-line-jump-lateral-speed";

// Cool down
import iceKnee from "../../../../exercises/ice-knee";
import hamstringStretch from "../../../../exercises/hamstring-stretch";
import hipFlexorStretch from "../../../../exercises/hip-flexor-stretch";
import calfStretch from "../../../../exercises/calf-stretch";
import itBandStretch from "../../../../exercises/it-band-stretch";
import incisionalMassage from "../../../../exercises/incisional-massage";
import quadStretch from "../../../../exercises/quad-stretch";
import glutealStretch from "../../../../exercises/gluteal-stretch";
import activePsoasStretch from "../../../../exercises/active-psoas-stretch";
import pretzelPirformisStretch from "../../../../exercises/pretzel-pirformis-stretch";
import ironCrossStretch from "../../../../exercises/iron-cross-stretch";

// Maintenance Checks
import checkUp from "../../../../exercises/checkup";
import restDay from "../../../../exercises/rest-day";

export default {
  //
  checkUp: [
    {
      description: "description",
      title: "check up",
      exercises: [checkUp],
    },
  ],

  restDay: [
    {
      description: "description",
      title: "rest day",
      exercises: [restDay],
    },
  ],

  // Weekdays
  week1day1: [
    {
      description: "description",
      title: "warm up",
      exercises: [stationaryBikeBeginner, extensionStretch],
    },

    {
      description: "description",
      title: "workout",
      exercises: [
        weightShifting,
        passiveFlexion,
        quadSets,
        straightLegRaise,
        heelSlides,
        patellarMobilization,
        sideLyingAbduction,
        proneExtension,
        calfRaises,
      ],
    },

    {
      description: "description",
      title: "cool down",
      exercises: [iceKnee],
    },
  ],

  week3day1: [
    {
      description: "description",
      title: "warm up",
      exercises: [stationaryBikeNormal, extensionStretch],
    },

    {
      description: "description",
      title: "workout",
      exercises: [
        miniSquats,
        calfRaises,
        wallSits60,
        legPress,
        heelSlides,
        bridges,
        proneCurls,
        proneExtension,
        sideLyingAbduction,
      ],
    },

    {
      description: "description",
      title: "cool down",
      exercises: [iceKnee],
    },
  ],

  week5day1: [
    {
      description: "description",
      title: "warm up",
      exercises: [
        stationaryBikeBeginner,
        activeKneeFlexion,
        activeKneeExtension,
        passiveProneFlexion,
        passiveExtension,
      ],
    },

    {
      description: "description",
      title: "workout",
      exercises: [
        singleLegHeelRaise,
        singleLegBalance30sec,
        doubleLegBalance,
        legPress,
        standing4WayHipExtensions,
        wallSits60,
        stepUpForward,
        stepUpLateral,
        stepUpRetro,
        miniSquats,
        bridges,
        swissBallBridge,
        swissBallCurls,
        terminalKneeExtensions,
      ],
    },

    {
      description: "description",
      title: "cool down",
      exercises: [
        hamstringStretch,
        hipFlexorStretch,
        calfStretch,
        itBandStretch,
        iceKnee,
        incisionalMassage,
      ],
    },
  ],

  week7day2: [
    {
      description: "description",
      title: "warm up",
      exercises: [
        stationaryBikeBeginner,
        activeKneeFlexion,
        activeKneeExtension,
        passiveProneFlexion,
        passiveExtension,
      ],
    },

    {
      description: "description",
      title: "workout",
      exercises: [crunches, planks, vSits, obliqueCrunches, flutterKicks],
    },

    {
      description: "description",
      title: "cool down",
      exercises: [
        hamstringStretch,
        hipFlexorStretch,
        calfStretch,
        itBandStretch,
        iceKnee,
        incisionalMassage,
      ],
    },
  ],

  week7day6: [
    {
      description: "description",
      title: "workout",
      exercises: [stationaryBikeAdvanced],
    },
  ],

  week9day1: [
    {
      description: "description",
      title: "warm up",
      exercises: [stationaryBikeBeginner],
    },

    {
      description: "description",
      title: "workout",
      exercises: [
        legPress,
        singleLegPress,
        bodyWeightSquat,
        terminalKneeExtensions,
        calfRaises,
        singleLegBalance30sec,
        stepUpForward,
        stepUpLateral,
        stepUpRetro,
        eccentricStepDowns,
        standing4WayHipExtensions,
        wallSits90,
      ],
    },

    {
      description: "description",
      title: "cool down",
      exercises: [
        hamstringStretch,
        hipFlexorStretch,
        calfStretch,
        itBandStretch,
        quadStretch,
        glutealStretch,
      ],
    },
  ],

  week9day2: [
    {
      description: "description",
      title: "warm up",
      exercises: [elliptical],
    },

    {
      description: "description",
      title: "workout",
      exercises: [
        hamstringCurls,
        bridges,
        swissBallBridge,
        swissBallCurls,
        swissBallBridgesKneeBent,
        singleLegBridges,
        clamShells,
        sideLyingAbduction,
        crunches,
        planks,
        vSits,
        obliqueCrunches,
        flutterKicks,
      ],
    },

    {
      description: "description",
      title: "cool down",
      exercises: [
        hamstringStretch,
        hipFlexorStretch,
        calfStretch,
        itBandStretch,
        quadStretch,
        glutealStretch,
      ],
    },
  ],

  week13day1: [
    {
      description: "description",
      title: "warm up",
      exercises: [treadMill],
    },

    {
      description: "description",
      title: "workout",
      exercises: [
        legPress,
        singleLegPress,
        wallSits90,
        lungeWalks,
        singleLegBalance1min,
        stepUpForward,
        stepUpLateral,
        stepUpRetro,
        eccentricStepDowns,
        singleLegSportCord,
        doubleLegSportCord,
        jumpSquat,
        doubleLegLineJumpForwardBackward,
        doubleLegLineJumpLateral,
        doubleLegStepDropOff,
        doubleLegLineJumpLateralSpeed,
      ],
    },

    {
      description: "description",
      title: "cool down",
      exercises: [
        hamstringStretch,
        hipFlexorStretch,
        calfStretch,
        itBandStretch,
        quadStretch,
        glutealStretch,
      ],
    },
  ],

  week13day2: [
    {
      description: "description",
      title: "warm up",
      exercises: [elliptical],
    },

    {
      description: "description",
      title: "workout",
      exercises: [
        hamstringCurls,
        bridges,
        swissBallBridge,
        swissBallCurls,
        swissBallBridgesKneeBent,
        singleLegBridges,
        clamShells,
        sideLyingAbduction,
        crunches,
        planks,
        vSits,
        obliqueCrunches,
        flutterKicks,
      ],
    },

    {
      description: "description",
      title: "cool down",
      exercises: [
        activePsoasStretch,
        pretzelPirformisStretch,
        ironCrossStretch,
      ],
    },
  ],
};
