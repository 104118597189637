export default {
  complete: false,
  title: "Eccentric Step Downs",
  training: {
    title: "repetitions",
    amount: "2 sets of 12",
  },
  equipmentNeeded: ["6 to 12 inch step"],
  media: "./assets/images/exercises/EccentricStepDown.gif",
  description:
    "Balance on your affected leg on a step and lower your unaffected leg beyond the edge of the step. Try and keep knee lined up under your hip. Hold at bottom for 2 seconds and raise back up.",
};
