export default {
  complete: false,
  title: "Double Leg Step Drop Off",
  training: {
    title: "repetitions",
    amount: "2 sets of 10 times (hold for 2 seconds)",
  },
  equipmentNeeded: ["Step"],
  media: "./assets/images/exercises/stepdropoff.gif",
  description:
    "On a step or box, take one foot and hang it off the edge, then drop off into a squat stance. Repeat with other leg.",
};
