export default {
  pages: {
    account: {
      enrolledProtocols: {
        text: {
          button: "launch",
          title: "enrolled protocols",
        },
      },

      myAccount: {
        options: {
          feet: [3, 4, 5, 6, 7],
          inches: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        },

        text: {
          age: "age",
          button: "continue",
          feetSymbol: "ft",
          female: "female",
          gender: "gender",
          height: "height",
          inchSymbol: "in",
          logOut: "log out",
          male: "male",
          reset: "reset all",
          title:
            "to ensure you get optimal care, please provide the following information.",
          weight: "weight (lbs)",
        },
      },
    },

    protocols: {
      text: {
        searchbarPlaceholder: "search...",
        title: "how can we help you?",
      },
    },
  },
};
