export default {
  complete: false,
  title: "Passive Extension",
  training: {
    title: "repetitions",
    amount: "10 times (hold for 10 seconds)",
  },
  equipmentNeeded: ["Towel/Strap (Belt)"],
  media: "./assets/images/exercises/PassiveKneeExtension.gif",
  description:
    "Place towel roll (prop) under the ankle and place a towel around the toes of the foot. Contract the quads to push into the towel roll and pull back on the towel until full knee extension is achieved. Hold for 10 seconds and relax.",
};
