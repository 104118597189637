export default {
  complete: false,
  title: "Lunge Walks",
  training: {
    title: "repetitions",
    amount: "3 sets of 15",
  },
  equipmentNeeded: ["Floor"],
  media: "./assets/images/exercises/walkinglunge.gif",
  description:
    "Step forward into a lunge and then bring your back foot forward into a standing position. Continue doing forward lunges as space allows and then turn around and continue.",
};
