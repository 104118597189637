import Weeks from "./weeks/_index";

export default [
  {
    length: "2 weeks",
    title: "phase 1",
    weeks: Weeks.phase1,
  },

  {
    length: "2 weeks",
    title: "phase 2",
    weeks: Weeks.phase2,
  },

  {
    length: "4 weeks",
    title: "phase 3",
    weeks: Weeks.phase3,
  },

  {
    length: "4 weeks",
    title: "phase 4",
    weeks: Weeks.phase4,
  },

  {
    length: "4 weeks",
    title: "phase 5",
    weeks: Weeks.phase5,
  },
];
