export default {
  complete: false,

  description:
    "Position yourself on the seat and adjust the seat to allow approximately 5-10˚ of bend in the knee with the foot on the pedals. Slowly begin to pedal, making full rotations if possible. You may have to rock forward and backward before making full revolutions (if you feel any sharp pain, stop and notify your PT).",

  equipmentNeeded: ["bike (peddler)"],

  levels: [
    {
      alternateExercises: [],
      title: "easier",
    },

    {
      alternateExercises: [],
      title: "harder",
    },
  ],

  media: "./assets/images/exercises/StationaryBike.gif",

  title: "Stationary Bike",

  training: {
    title: "duration",
    amount: "15 minutes",
  },
};
