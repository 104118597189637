export default {
  complete: false,
  title: "Swiss Ball Plank II",
  training: {
    title: "Repetitions",
    amount: "3 repetitions of 30 seconds",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballplanks2.gif",
  video: "Swiss Ball Planks.m4v",
  description:
    "Place both forearms on the swiss ball and raise yourself onto your toes. Keeping abs tight and back straight, hold position for 30 seconds. ",
};
