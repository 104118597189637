import Routines from "./routines";

export default [
  // Each object is a day
  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week12day1,
    title: "Advanced Core Strength IV-A",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week12day2,
    title: "Advanced Core Strength IV-B",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week12day1,
    title: "Advanced Core Strength IV-A",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week12day2,
    title: "Advanced Core Strength IV-B",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week12day1,
    title: "Advanced Core Strength IV-A",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week12day2,
    title: "Advanced Core Strength IV-B",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week12day1,
    title: "Advanced Core Strength IV-A",
  },
];
