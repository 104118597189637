export default {
  complete: false,
  title: "Side Plank IV",
  training: {
    title: "Repetitions",
    amount: "15 repetitions on each side",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/sideplanks4.gif",
  video: "Side Plank with Abduction - 3.m4v",
  description:
    "Lay on your side with both legs straight on top of each other. With your forearm for support, tighten your core, and keep your back straight. Lift hips off of the floor and extend top leg upwards, holding for 3 seconds. Return leg to starting position and repeat.",
};
