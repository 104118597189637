export default {
  complete: false,
  title: "Swiss Ball Flexion",
  training: {
    title: "Repetitions",
    amount: "30 repetitions for each arm",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballunilateralarmflexion.gif",
  video: "Supine Swiss Ball Unilateral Arm Flexion.m4v",
  description:
    "Lie on the swiss ball with your upper back on the center, keeping your hips off of the ball. Bring one arm overhead and hold for a moment before returning to the starting position. Repeat on each side while keeping hips straight. ",
};
