export default {
  complete: false,
  title: "Swiss Ball Crunch",
  training: {
    title: "Repetitions",
    amount: "30 repetitions",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballcrunch.gif",
  video: "Swiss Ball Crunches.m4v",
  description:
    "Lie on the ball with low back on center of the ball and arms behind your head. Using your abs, reach for the ceiling, coming into a 1/2 sitting position with back straight and abs tight. Return to starting position and repeat.",
};
