export default {
  complete: false,
  title: "Abduction",
  training: {
    title: "Repetitions",
    amount: "30 repetitions on each side",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/sidelyingabduction.gif",
  video: "Sidelying Abduction.m4v",
  description:
    "Lie on side with bottom leg bent and upper leg straight. Slowly lift top leg 6 inches from the bottom leg. Hold for 2 seconds. Shoulders, hips, and feet should all be in a straight line. Repeat on opposite side.",
};
