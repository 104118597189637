import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  process.env.VUE_APP_SUPABASE_URL as string,
  process.env.VUE_APP_SUPABASE_KEY as string,
  {}
);

export default function useSupabase() {
  return supabase;
}

export const getPagination = (page: number, size: number) => {
  const limit = size ? +size : 3;
  const from = page ? page * limit : 0;
  const to = page ? from + size - 1 : size - 1;

  return { from, to };
};

export const CONTENT_ENV_TYPE =
    process.env.VUE_APP_VERCEL_ENV === "production" ? "prod" : "test";
