export default {
  complete: false,
  title: "Pike I",
  training: {
    title: "Duration",
    amount: "3 repetitions of 30 seconds",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/pike1.gif",
  video: "Pike 1.m4v",
  description:
    "Sit upright with legs out and arms by your side. Bring knees up so they are bent and lift up off surface about 6 inches. Lean back slightly without rolling backwards and hold for 30 seconds.",
};
