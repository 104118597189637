export default {
  complete: false,
  title: "Single Leg Heel Raise",
  training: {
    title: "repetitions",
    amount: "3 sets of 10",
  },
  equipmentNeeded: ["Step"],
  media: "./assets/images/exercises/SingleLegHeelRaise.gif",
  description:
    "While standing on one foot, go up onto toes. Use table or railing for balance if needed.",
};
