export default {
  complete: false,
  title: "Bird Dog (arm only)",
  training: {
    title: "Duration",
    amount: "90 seconds",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/quadrupedunilateralarmflexion.gif",
  video: "Bird Dog - Arms.m4v",
  description:
    "Get onto hands and knees without arching your back. Stabilize your core and align your head, back and hips. Lift one arm out in front of you without arching your back. Hold arm extended for 5 seconds and then alternate.",
};
