export default {
  complete: false,
  title: "Pike II",
  training: {
    title: "Repetitions",
    amount: "20 repetitions",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/pikes2.gif",
  video: "Low Boat Rocks.m4v",
  description:
    "Balance on your hips with your back and legs straightened and off the ground. With a tight core, move your upper and lower body inwards to form a v-shape. Slowly return to the starting position and repeat.",
};
