export default {
  complete: false,
  title: "Level 4 Plank",
  training: {
    title: "Repetitions",
    amount: "20 repetitions",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/plank4.gif",
  video: "Plank With Arm Reach.m4v",
  description:
    "Get onto toes and forearms, keeping abs tight and back straight. Raise one arm out in front of you. Hold for 3 seconds and repeat on other side.",
};
