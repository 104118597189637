export default {
  complete: false,

  title: "Ice Knee",

  training: {
    title: "duration",
    amount: "10 minutes",
  },

  equipmentNeeded: ["ice pack"],

  media: "./assets/images/exercises/IceKnee.gif",

  description:
    "Wrap affected knee with gel ice pack and prop up on bolster to elevate above your heart.",
};
