export default {
  complete: false,
  title: "Planks",
  training: {
    title: "Repetitions",
    amount: "3 x 1 minute",
  },
  equipmentNeeded: [],
  altEquipmentNeeded: ["Alternating leg/arm"],
  media: "./assets/images/exercises/plank.gif",
  description:
    "While lying face down, lift your body up on your elbows and toes. Try and maintain a straight spine. Do not allow your hips or pelvis on either side to drop",
};
