export default {
  complete: false,
  title: "Swiss Ball Bridges Knee Bent",
  training: {
    title: "repetitions",
    amount: "3 sets of 10 (hold for 2 seconds)",
  },
  equipmentNeeded: ["Swiss Ball"],
  media: "./assets/images/exercises/swissballbentkneebridges.gif",
  description:
    "Start with your hips on the ground and your knees bent with your feet on the swiss ball. Then lift your hips so that you are in a bridge with knees bent. Hold for 2 seconds and keep your core tight.",
};
