import { defineStore } from "pinia";
import { toastController } from "@ionic/vue";
import { ToastOptions } from "@ionic/core";

type ToastPosition = "bottom" | "middle" | "top";

interface ToasterState {
  toast: HTMLIonToastElement | null;
}

export const useToaster = defineStore("toaster", {
  state: (): ToasterState => ({
    toast: null,
  }),
  actions: {
    async openSimpleToast(message: string, duration = 2000) {
      this.toast = await toastController.create({
        message,
        duration,
      });
      return this.toast.present();
    },
    async openToast({
      header,
      message,
      position = "top",
      buttons,
      ...rest
    }: ToastOptions) {
      this.toast = await toastController.create({
        header,
        message,
        position,
        buttons,
        ...rest,
      });
      await this.toast.present();

      const { role } = await this.toast.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
  },
});
