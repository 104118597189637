import EnrollInfo from "./enroll-info";
import Phases from "./phases";

export default {
  id: 0,
  category: "surgical rehab",
  enrolled: false,
  enrollInfo: EnrollInfo,
  length: "16 weeks",
  media: "./assets/images/protocols/acl-protocol-splash.jpg",
  title: "anterior cruciate ligament (ACL) reconstruction",
  phases: Phases,
};
