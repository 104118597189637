export default {
  complete: false,
  title: "Clamshells",
  training: {
    title: "repetitions",
    amount: "3 sets of 20",
  },
  equipmentNeeded: ["floor"],
  media: "./assets/images/exercises/clamshells.gif",
  description:
    "Laying on your side, bend your knees and keep your legs together. Lift your top leg open, while keeping your feet together in a clam movement. Then close and repeat.",
};
