export default {
  complete: false,
  title: "Active Knee Flexion",
  training: {
    title: "repetitions",
    amount: "10 times (hold for 10 seconds)",
  },
  equipmentNeeded: ["Chair/Surface"],
  media: "./assets/images/exercises/ActiveKneeFlexion.gif",
  description:
    "While sitting, place uninvolved leg over involved leg. Use uninvolved leg to help you bend the opposite knee. You should feel a stretch in the front of your knee.",
};
