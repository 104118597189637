export default {
  complete: false,
  title: "Calf Raises",
  training: {
    title: "Repetitions",
    amount: "30",
  },
  equipmentNeeded: ["Floor"],
  altEquipmentNeeded: ["Step"],
  media: "./assets/images/exercises/calfraise.gif",
  description:
    "While standing, raise up on your toes as you lift your heels off the ground",
};
