export default {
  complete: false,
  title: "Marching III",
  training: {
    title: "Duration",
    amount: "60 seconds",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballmarchesbilateralarms.gif",
  video: "Swiss Ball Marching w-Bilateral Arm Flexion.m4v",
  description:
    "Sitting on a swiss ball with feet flat and upright posture, lift one leg 6 inches off ground and bring both arms straight overhead. Hold for a moment before returing to starting position. Repeat for opposite leg.",
};
