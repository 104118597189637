export default {
  complete: false,
  title: "Hamstring Curls",
  training: {
    title: "Repetitions",
    amount: "20 repetitions",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballlegcurls.gif",
  video: "Swiss Ball Leg Curls.m4v",
  description:
    "Lie on your back with both feet on the swiss ball. Tighten your core and keep back and arms on the floor. Pull ball towards your glutes and hold for 3 seconds and then push ball outwards. ",
};
