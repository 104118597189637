export default {
  complete: false,
  title: "Balance",
  training: {
    title: "Repetitions",
    amount: "3 x 30 sec",
  },
  equipmentNeeded: ["Foam Pads"],
  altEquipmentNeeded: ["BOSU"],
  media: "./assets/images/exercises/bapsbalanceboard.gif",
  description:
    "Start standing on the balance board or BOSU with both feet. Control Board or ball with slight bend in the knees. Hold onto table or rail for stability",
};
