export default {
  complete: false,
  title: "Weight Shifting",
  training: {
    title: "duration",
    amount: "3 minutes",
  },
  equipmentNeeded: ["floor"],
  media: "./assets/images/exercises/WeightShifting.gif",
  description:
    "While in a standing position and holding a steady surface, such as a countertop, slowly shift your body weight side-to-side to unweight each leg. Rock forward and backwards from heel to toes also.",
};
