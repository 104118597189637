export default {
  complete: false,
  title: "Crunches",
  training: {
    title: "Repetitions",
    amount: "100",
  },
  equipmentNeeded: [],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/crunches.gif",
  description:
    "Lie with knees bent and feet flat on the ground. Extend arms in front of you towards the knees, and when you crunch you abs, move hands closer to knees.",
};
