export default {
  complete: false,
  title: "Step Up Lateral",
  training: {
    title: "repetitions",
    amount: "30 times",
  },
  equipmentNeeded: ["12 inch step"],
  media: "./assets/images/exercises/StepUpLateral.gif",
  description:
    "Stand next to the box with the affected leg next to it. Step upward/laterally onto the step. Pause before bringind down your other leg. Step down and repeat.",
};
