export default {
  complete: false,
  title: "Plank III",
  training: {
    title: "Duration",
    amount: "3 repetitions of 30 seconds",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/planks3.gif",
  video: "Plank With Leg Lift.m4v",
  description:
    "Get on toes and forearms with your core tight. Keep back and hips in line without arching. Lift one leg 6 inches off ground without moving hips and hold for 3 seconds. Repeat with other leg.",
};
