export default {
  about:
    "The anterior cruciate ligament is essential for normal function of the knee. It is often injured during  complex movements that occur from sudden deceleration duirng high velocity movement.",
  background: "./assets/images/protocols/acl-protocol-splash.jpg",
  candidate:
    "This routine is designed for individuals who have or are preparing to undergo surgical repair for a torn anterior cruciate ligament.",
  description:
    "If you are recovering from ACL surgery, this is the routine for you. Get back to full strength with this progressive rehab routine.",
  equipmentNeeded: [
    "Therabands",
    "Swiss Ball",
    "Stationary Bike",
    "Leg press Machine",
    "Hamstring Curl Machine",
    "Balance Board",
    "Sport Cord",
    "Foam Roller",
    "Steps",
    "Ice Pack",
  ],
  media: "./assets/images/protocols/acl-reconstruction-enroll.png",
  summary:
    "The ACL can be partially or fully torn. To ensure proper treatment, it is recommended that individuals consult a doctor or specialist to fully comprehend the extend of their injury.",
};
