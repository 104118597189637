export default {
  complete: false,
  title: "Plank II",
  training: {
    title: "Duration",
    amount: "3 repetitions of 30 seconds",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/plank2.gif",
  video: "Planks.m4v",
  description:
    "Get onto your toes and forearms keeping your core tight. With your back and hips in line, hold the position for 30 seconds without arching your back.",
};
