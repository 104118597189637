export default {
  complete: false,
  title: "Straight Leg Bridge",
  training: {
    title: "Repetitions",
    amount: "20 repetitions on each side",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/singlelegbridgewstraightleg.gif",
  video: "Single Leg Bridge with Straight Leg.m4v",
  description:
    "From a bridge position, raise one leg straight out. Lift hips and hold for 5 seconds before returning to starting position. Repeat on opposite side.",
};
