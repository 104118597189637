export default {
  complete: false,

  title: "See Your Doctor",

  training: {
    title: "",
    amount: "Schedule Appointment",
  },

  equipmentNeeded: [],

  media: "./assets/images/exercises/doctorcheckin.gif",

  description:
    "At 2 weeks, it's important to check in with your doctor to evaluate your progress and make sure you are on track with your rehabilitation.",
};
