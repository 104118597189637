export default {
  complete: false,
  title: "Hamstring Stretch",
  training: {
    title: "Repetitions",
    amount: "5 x 20 sec",
  },
  equipmentNeeded: [],
  altEquipmentNeeded: ["Standing"],
  media: "./assets/images/exercises/hamstringstretch.gif",
  description:
    "Foot being stretched is elevated on a steady stool or chair with the knee in slight flexion. Anterior pelvic tilt is maintained while the patient leans forward from the hips until a stretch is felt along the posterior thigh then rotate the pelvis to the right and then left",
};
