export default {
  complete: false,
  title: "Passive Flexion",
  training: {
    title: "repetitions",
    amount: "20 times (hold for 10 seconds)",
  },
  equipmentNeeded: ["chair"],
  media: "./assets/images/exercises/ActiveKneeFlexion.gif",
  description:
    "While seated upright with hands firmly placed on sitting surface, place your uninvolved leg over your affected leg. Next, use your unaffected leg to help bend the affected knee backwards. Hold for 10 secs, relax and repeat. Make sure to stay seated during this process.",
};
