import AnkleFootProtocols from "./ankle-foot/_index";
import AquaticsProtocols from "./aquatics/_index";
import CardiovascularProtocols from "./cardiovascular/_index";
import CervicalSpineProtocols from "./cervical-spine/_index";
import ElbowHandProtocols from "./elbow-hand/_index";
import ErgonomicsPostureProtocols from "./ergonomics-posture/_index";
import HipProtocols from "./hip/_index";
import KneeProtocols from "./knee/_index";
import LumbarThoracicSpine from "./lumbar-thoracic-spine/_index";
import MiscProtocols from "./misc/_index";
import ShoulderProtocols from "./shoulder/_index";

export type Exercise = {
  complete: boolean;
  title: string;
  training: {
    title: string;
    amount: string;
  };
  equipmentNeeded: string[];
  altEquipmentNeeded?: string[];
  media: string;
  video?: string;
  description: string;
};
export type Routine = Record<
  string,
  Array<{
    description: string;
    title: string;
    exercises: Exercise[];
  }>
>;
export type Day = Record<
  string,
  Array<{
    complete: boolean;
    length: string;
    locked: boolean;
    routines: Routine;
    title: string;
  }>
>;
export type WeekList = Record<
  string,
  Array<{
    complete: boolean;
    locked: boolean;
    title: string;
    days: Day[];
  }>
>;
export type Week = Record<
  string,
  Array<{
    complete: boolean;
    locked: boolean;
    title: string;
    days: Day[];
  }>
>;
export type Phase = {
  length: string | number;
  title: string;
  weeks: WeekList;
};
export type EnrollDetails = {
  about: string;
  background: string;
  candidate: string;
  description: string;
  equipmentNeeded: string[];
  media: string;
  summary: string;
};
export type Protocol = {
  id: number;
  category: string;
  enrolled: false;
  enrollInfo: EnrollDetails;
  length: string | number; // weeks
  media: string;
  title: string;
  phases: Phase[];
};
export type Group = {
  id: number;
  enabled: boolean;
  protocols: Protocol[];
  title: string;
};

export default [
  {
    id: 0,
    enabled: false,
    protocols: AnkleFootProtocols,
    title: "ankle foot",
  },

  {
    id: 1,
    enabled: false,
    protocols: AquaticsProtocols,
    title: "aquatics",
  },

  {
    id: 2,
    enabled: false,
    protocols: CardiovascularProtocols,
    title: "cardiovascular",
  },

  {
    id: 3,
    enabled: false,
    protocols: CervicalSpineProtocols,
    title: "cervical spine",
  },

  {
    id: 4,
    enabled: false,
    protocols: ElbowHandProtocols,
    title: "elbow hand",
  },

  {
    id: 5,
    enabled: false,
    protocols: ErgonomicsPostureProtocols,
    title: "ergonomics posture",
  },

  {
    id: 6,
    enabled: false,
    protocols: HipProtocols,
    title: "hip",
  },

  {
    id: 7,
    enabled: true,
    protocols: KneeProtocols,
    title: "knee",
  },

  {
    id: 8,
    enabled: true,
    protocols: LumbarThoracicSpine,
    title: "lumbar thoracic-spine",
  },

  {
    id: 9,
    enabled: false,
    protocols: MiscProtocols,
    title: "misc",
  },

  {
    id: 10,
    enabled: false,
    protocols: ShoulderProtocols,
    title: "shoulder",
  },
];
