export default {
  complete: false,
  title: "Swiss Ball Plank III",
  training: {
    title: "Repetitions",
    amount: "30 repetitions",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballplanks3.gif",
  video: "Swiss Ball Planks - Phase 3.m4v",
  description:
    "Place both forearms onto the swiss ball and raise self onto your toes. Keep abs tight and back in line with your body. Lift one leg 6 inches off the ground, hold for a moment, and return to starting position. Repeat on each side. Do not let back arch or hips drop.",
};
