/* eslint-disable @typescript-eslint/ban-ts-comment */
import "es6-promise/auto";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import * as IonComponents from "@ionic/vue";
import { IonicVue } from "@ionic/vue";
import ElementPlus from "element-plus";
import VueCal from "vue-cal";
import supabase from "vue-3-supabase";
import { createPinia } from "pinia";
import "./registerServiceWorker";

import useSupabase from "@/composable/useSupabase";
import ExerciseListItem from "@/components/ExerciseListItem.vue";

import { userSession } from "@/state/authState";
import store from "@/store";

import PageWrapper from "@/components/shared/PageWrapper.vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";
import "element-plus/dist/index.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import "./theme/phix.scss";
import "vue-cal/dist/vuecal.css";

const app = createApp(App)
  .use(supabase, {
    supabaseUrl: process.env.VUE_APP_SUPABASE_URL as string,
    supabaseKey: process.env.VUE_APP_SUPABASE_KEY as string,
    options: {},
  })
  .use(createPinia())
  .use(store)
  .use(IonicVue)
  .use(ElementPlus)
  .use(router);

defineCustomElements(window);

for (const IonComponentKey in IonComponents) {
  if (IonComponentKey.startsWith("Ion")) {
    // @ts-ignore
    app.component(IonComponentKey, IonComponents[IonComponentKey]);
  }
}

app.component("PageWrapper", PageWrapper);
app.component("VueCal", VueCal);
app.component("ExerciseListItem", ExerciseListItem);

useSupabase().auth.onAuthStateChange((event, session) => {
  console.log({ event, session });
  userSession.value = session;
});

router.isReady().then(async () => {
  // await store.dispatch("fetchUserProfile", user);
  app.mount("#app");
});
