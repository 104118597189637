export default {
  complete: false,

  title: "Rest Day",

  training: {
    title: "duration",
    amount: "Full Day",
  },

  equipmentNeeded: [],

  media: "./assets/data/images/exercises/restday.gif",

  description:
    "Take the day to rest and recover. You can do basic stretching and icing as necessary, but allow your body to heal.",
};
