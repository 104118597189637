export default {
  complete: false,

  description:
    "Position yourself on the bike and adjust the seat as needed. Set resistance based on what is comfortable and peddle at a steady pace for set time.",

  equipmentNeeded: ["bike (peddler)"],

  levels: [
    {
      alternateExercises: [],
      title: "easier",
    },

    {
      alternateExercises: [],
      title: "harder",
    },
  ],

  media: "./assets/images/exercises/StationaryBike.gif",

  title: "Stationary Bike",

  training: {
    title: "duration",
    amount: "10 minutes",
  },
};
