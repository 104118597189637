export default {
  complete: false,
  title: "Squats",
  training: {
    title: "Repetitions",
    amount: "30 repetitions",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballwallsquat.gif",
  video: "Squats.m4v",
  description:
    "Stand upright and place feet shoulder width apart. Squat to a 90 degree angle (or as low as possible) with arms straight out and return to starting position. Try to drive pressure through your heels. Do not let your knees go over your toes when squatting.",
};
