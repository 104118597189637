import Routines from "./routines";

export default [
  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week3day1,
    title: "Advanced Range of Motion",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week3day1,
    title: "Advanced Range of Motion",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week3day1,
    title: "Advanced Range of Motion",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week3day1,
    title: "Advanced Range of Motion",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week3day1,
    title: "Advanced Range of Motion",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week3day1,
    title: "Advanced Range of Motion",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week3day1,
    title: "Advanced Range of Motion",
  },
];
