export default {
  complete: false,
  title: "Hamstring Curls",
  training: {
    title: "repetitions",
    amount: "3 sets of 10",
  },
  equipmentNeeded: ["Hamstring Curl Machine (Ankle Weights)"],
  media: "./assets/images/exercises/HamstringCurl.gif",
  description:
    "Make all necessary adjustments to the machine before starting exercise. Legs should start in an outstretched position. Pull the pad towards the buttocks either seated or on your stomach. Increase weight as needed.",
};
