export default {
  complete: false,
  title: "Bent Knee Bridge",
  training: {
    title: "Repetitions",
    amount: "20 repetitions for each leg",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/singlelegbentkneebridge.gif",
  video: "Single Leg Bridge with Knee Bent.m4v",
  description:
    "Lying on the floor with knees bent and feet on the ground, lift one leg and hold in a bent position. Perform a bridge, keeping one leg off of the ground. Hold for 5 seconds. Repeat with opposite leg.",
};
