import Routines from "./routines";

export default [
  {
    complete: false,
    length: "70 min",
    locked: true,
    routines: Routines.week9day1,
    title: "Strength & Flexibility Basics",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week9day2,
    title: "Strength & Core Basics",
  },

  {
    complete: false,
    length: "70 min",
    locked: true,
    routines: Routines.week9day1,
    title: "Strength & Flexibility Basics",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week9day2,
    title: "Strength & Core Basics",
  },

  {
    complete: false,
    length: "70 min",
    locked: true,
    routines: Routines.week9day1,
    title: "Strength & Flexibility Basics",
  },

  {
    complete: false,
    length: "60 min",
    locked: true,
    routines: Routines.week9day2,
    title: "Strength & Core Basics",
  },

  {
    complete: false,
    length: "full day",
    locked: true,
    routines: Routines.restDay,
    title: "Rest Day",
  },
];
