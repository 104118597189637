export default {
  complete: false,
  title: "Mini Squats",
  training: {
    title: "Repetitions",
    amount: "30",
  },
  equipmentNeeded: ["-0"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/minisquats.gif",
  description:
    "Stand facing a counter, with feet shoulder width apart. Use your hands for balance if needed. Perform a mini squat and back up to straightened position. Contract quads and glutes at end-range.",
};
