export default {
  complete: false,
  title: "Step Up Forward",
  training: {
    title: "repetitions",
    amount: "30 times",
  },
  equipmentNeeded: ["12 inch step"],
  media: "./assets/images/exercises/StepUpForward.gif",
  description:
    "Place one foot on the step and then step up (use support if needed). Be careful not to hop up onto the step, but to use the leg to do a controlled step-up. Repeat as shown.",
};
