export default {
  complete: false,
  title: "Single Leg Balance",
  training: {
    title: "repetitions",
    amount: "3 times (hold for 30 seconds)",
  },
  equipmentNeeded: ["Foam Pad (Pillow)"],
  media: "./assets/images/exercises/singlelegbalance.gif",
  description:
    "Stand on a foam pad/pillow with one leg and maintain your balance. Hold your opposite leg back behind or in front of your body. Maintain a slight bend in the knee.",
};
