export default {
  complete: false,
  title: "Gluteal Stretch",
  training: {
    title: "repetitions",
    amount: "3 times (hold for 30 seconds)",
  },
  equipmentNeeded: ["Floor"],
  media: "./assets/images/exercises/GluteStretch.gif",
  description:
    "Sit with one knee straight and the other bent and placed over the opposite knee. Then gentle turn your body towards the bend knee side.",
};
