export default {
  complete: false,
  title: "Pelvic Tilts",
  training: {
    title: "Repetitions",
    amount: "30 repetitions",
  },
  equipmentNeeded: ["Yoga Mat"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/pelvictilts.gif",
  video: "Posterior Pelvic Tilt.m4v",
  description:
    "Draw belly button inwards toward spine and rotate hips backwards as your back flattens. Keep back flat against surface. Hold for 3 seconds.",
};
