export default {
  complete: false,
  title: "Swiss Ball Balance & Lift",
  training: {
    title: "Repetitions",
    amount: "30 repetitions on both sides",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballbalancewlegraises.gif",
  video: "Swiss Ball Balance With Unilateral Leg Lift.m4v",
  description:
    "Position upper back on the center of the ball with legs out, bent at the knee. Lift one leg about 6 inches off of the floor and hold for 3 seconds. Repeat on each side while keeping hips straight.",
};
