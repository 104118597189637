export default {
  complete: false,
  title: "Double Leg Line Jump Lateral - Speed",
  training: {
    title: "repetitions",
    amount: "2 sets of 10",
  },
  equipmentNeeded: ["Floor"],
  media: "./assets/images/exercises/linejumpspeed.gif",
  description:
    "With both feet together, jump laterally over a line or cone. As soon as you land, jump back across, going as quickly as possible with good form.",
};
