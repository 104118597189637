export default {
  complete: false,
  title: "Swiss Ball Balance",
  training: {
    title: "Duration",
    amount: "3 repetitions of 60 seconds",
  },
  equipmentNeeded: ["Swiss Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/swissballbalancing.gif",
  video: "Swiss Ball Supine Balancing.m4v",
  description:
    "Lie on a swiss ball with your upper back on the center of the ball, keeping hips off the ball. Bring both arms straight up towards ceiling. Hold position for 1 minute. Do not let hips drop. Repeat 3 times.",
};
