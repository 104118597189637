import Routines from "./routines";

export default [
  // Each object is a day
  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week6day1,
    title: "Moderate Core Strength II-A",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week6day2,
    title: "Moderate Core Strength II-B",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week6day1,
    title: "Moderate Core Strength II-A",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week6day2,
    title: "Moderate Core Strength II-B",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week6day1,
    title: "Moderate Core Strength II-A",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week6day2,
    title: "Moderate Core Strength II-B",
  },

  {
    complete: false,
    length: "30 min",
    locked: true,
    routines: Routines.week6day1,
    title: "Moderate Core Strength II-A",
  },
];
