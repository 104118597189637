export default {
  complete: false,
  title: "High Kneeling Diagonals",
  training: {
    title: "Repetitions",
    amount: "20 repetitions",
  },
  equipmentNeeded: ["Dumbell/Medicine Ball"],
  altEquipmentNeeded: [],
  media: "./assets/images/exercises/kneelingdiagonals.gif",
  video: "High Kneeling Diagonals.m4v",
  description:
    "Get into a kneeling position with a 5lb dumbell held in you hands near the ground. Keeping your core tight, raise the weight in diagonal pattern above your head without arching your back. Repeat on opposite side.",
};
