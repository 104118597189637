export default {
  complete: false,
  title: "Double Leg Line Jump Lateral",
  training: {
    title: "repetitions",
    amount: "2 sets of 10 times (hold for 2 seconds)",
  },
  equipmentNeeded: ["Floor"],
  media: "./assets/images/exercises/linejumplateral.gif",
  description:
    "With both feet together, jump laterally over a line or cone, holding for 2 seconds after each jump.",
};
